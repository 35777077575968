import { Button, Stack, Typography, Box } from "@mui/material";
import { CustomDndTable, Loader } from "../../../shared";
import { createColumnHelper } from "@tanstack/react-table";

export const SubCategoryProductsDialog = (props) => {
  const {
    handleModal,
    ColorButton,
    productsList,
    onSearchChange,
    onCategoryProductOrderChange,
    handleProductSortOrder,
    recipeListQuery,
    category,
  } = props;

  const style = {
    position: "sticky",
    top: "100%",
    left: "90%",
    p: 3,
  };

  const columnHelper = createColumnHelper(productsList);

  const columns = [
    columnHelper.accessor("name", {
      header: () => "Product",
    }),
  ];

  return (
    <>
      {recipeListQuery?.isPending ? (
        <Loader />
      ) : (
        <>
          <Box
            sx={{
              overflowY: "auto",
            }}
          >
            <CustomDndTable
              defaultData={productsList}
              columns={columns}
              headerText="Product"
              subRow={"subRows"}
              allRowsExpanded={true}
              manualPagination={true}
              isPagination={false}
              isSearching={false}
              onSearchChange={onSearchChange}
              disableDrag={category?.searchKey}
              searchText={category?.searchKey}
              customRowStyle={(row) => {
                return !row.depth ? { borderBottom: "none" } : null;
              }}
              onRowOrderChange={(data) => onCategoryProductOrderChange(data)}
            />
          </Box>
          <Stack
            direction="row"
            gap={1}
            padding="10px 10px"
            alignSelf="end"
            sx={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "white",
              zIndex: 1,
              ...style,
            }}
          >
            <ColorButton variant="outlined" onClick={() => handleModal(false)}>
              Close
            </ColorButton>
            <Button
              variant="contained"
              onClick={handleProductSortOrder}
              disabled={!category?.recipeListItems?.length}
              size="small"
            >
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  );
};
