import { Card, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { CustomTable } from "../../../shared";
import StarIcon from "../../../assets/images/Icons/starIcon.svg";
import emptyIcon from "../../../assets/images/Icons/search-table.png";
import { formatWeightWithUnits } from "../../../utils";

export const OtherRecipes = (props) => {
  const { selectedOptionRecipe, templateDetails } = props;

  const IngColumns = useMemo(() => {
    const IngColumnHelper = createColumnHelper(
      selectedOptionRecipe?.ingredients
    );

    return [
      IngColumnHelper.accessor("ingredient_name", {
        header: () => "Ingredients",
        cell: (props) => {
          const { row } = props;
          return (
            <Stack direction="row" alignItems="center" gap="10px">
              <Typography>
                {row?.original?.ingredient_name
                  ? row?.original?.ingredient_name
                  : row?.original?.recipe_name}
              </Typography>
              {row?.original?.pivot?.is_main_ingredient === 1 && (
                <img
                  src={StarIcon}
                  alt="StarIcon"
                  style={{ width: "13px", height: "14px" }}
                />
              )}
            </Stack>
          );
        },
      }),

      IngColumnHelper.accessor("base_unit_qty", {
        header: () => "Quantity",
        align: "right",
        cell: (props) => {
          const { row } = props;

          return (
            <Typography component="div">
              <span>
                {formatWeightWithUnits(
                  row?.original?.pivot?.base_unit_qty,
                  row?.original?.pivot?.base_unit_symbol,
                  3
                )}
              </span>
            </Typography>
          );
        },
      }),
    ];
  }, [templateDetails?.isFetching]);
  return (
    <Card
      sx={{
        minHeight: "560px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {selectedOptionRecipe?.ingredients.length ? (
        <>
          {selectedOptionRecipe && (
            <>
              <Typography
                variant="h6"
                fontWeight={600}
                lineHeight="19px"
                minWidth="160px"
                padding="12px"
              >
                {selectedOptionRecipe?.recipe_name}
              </Typography>
              <CustomTable
                isTableHeader={false}
                defaultData={selectedOptionRecipe?.ingredients ?? []}
                columns={IngColumns}
                isPagination={false}
                showSearchBar={false}
                isLoading={templateDetails?.isFetching}
              />
            </>
          )}
        </>
      ) : (
        <Stack alignItems="center" gap="8px" mt={25}>
          <img src={emptyIcon} alt="empty" height={56} width={56} />
          <Typography
            sx={{ fontWeight: 400, fontSize: "14px", color: "#485364" }}
          >
            No Ingredients to show!
          </Typography>
        </Stack>
      )}
    </Card>
  );
};
