import { API } from "../../../utils/api";

export const getLabelPrintingList = async ({ query, pagination }) => {
  const { pageIndex, pageSize } = pagination;
  const res = await API.get(
    `label-printing?page=${pageIndex}&per_page=${pageSize}`,
    query
  );
  return res.data.data;
};

export const saveLabelPrinting = async (payload) => {
  const res = await API.post("label-printing", payload);
  return res.data.data;
};

export const getLabelPrintingById = async (id) => {
  const res = await API.get(`label-printing/${id}`);
  return res.data.data;
};
export const editLabelPrinting = async (payload, detailsId) => {
  const res = await API.put(`label-printing/${detailsId}`, payload);
  return res.data;
};
export const removeLabelPrinting = async ({ id }) => {
  const res = await API.delete(`label-printing/${id}`);
  return res.data;
};
