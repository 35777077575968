import { useContext, useMemo } from "react";
import { AppContext } from "../../store";

export const useLabelType = ({ onChangeTypeHandler }) => {
  const { globalData } = useContext(AppContext);
  const typeOptions = useMemo(() => {
    return (
      globalData?.labelPrinting_types?.map((type) => ({
        id: type.value,
        name: type.label,
      })) || []
    );
  }, [globalData?.labelPrinting_types]);

  const typeValueChangeHandler = (option) => {
    const selectedOption = option ? option.id : "";
    onChangeTypeHandler(selectedOption);
  };
  return { typeOptions, typeValueChangeHandler };
};
