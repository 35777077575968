import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import {
  DeleteModal,
  InputControl,
  PageListLayout,
  SimpleMenu,
  SelectChip,
  Loader,
  CustomTable,
  LimitedTag,
} from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useArchiveRecipe, useRecipeList } from "../hooks";
import ArrowRight from "../../../assets/images/Icons/arrow_right.png";
import LeftArrow from "../../../assets/images/Icons/left_arrow.png";
import { useImmer } from "use-immer";

export const RecipeList = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const [scrollPosition, setScrollPosition] = useImmer({
    scrollLeft: null,
    width: null,
  });
  const {
    recipe,
    selectType,
    selectStatus,
    selectChipCategory,
    selectStore,
    setBatchGroup,
    onSearchHandler,
    onClearFilter,
    pagination,
    setPagination,
    deleteInfo,
    resetDeleteInfo,
    confirmDelete,
    sorting,
    setSorting,
    disableclearButton,
    onClearSearch,
    StoreValuesHandler,
    filterOptions,
    isCreateVisible,
    isEditable,
    tableData,
    moduleName,
    search,
  } = useRecipeList({ recipelist: true });
  const { onArchiveRecipeCreate } = useArchiveRecipe();

  const handleScroll = () => {
    setScrollPosition((draft) => {
      draft.scrollLeft = ref.current.scrollLeft;
      draft.width = ref.current.clientWidth;
    });
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (ref.current) {
        ref.current.addEventListener("scroll", handleScroll);
      }
    };
  }, [ref?.current]);

  const onScrollRefClick = (direction) => {
    const width = direction === "left" ? -250 : 250;
    ref.current.scrollLeft += width;
  };

  if (selectChipCategory.chipCategory.isLoading) {
    return <Loader />;
  }

  const listStoreChipls = (stores) => {
    if (stores) {
      if (stores.length < 3) {
        return stores.map((data) => (
          <Chip size="small" variant="outlined" color="gray" label={data} />
        ));
      } else {
        const limitedChips = [];
        const moreStores = [];
        for (var i = 0; i < stores.length; i++) {
          if (i < 2) {
            var data = stores[i];
            const chip = (
              <Chip size="small" variant="outlined" color="gray" label={data} />
            );
            limitedChips.push(chip);
          } else {
            var data = stores[i];
            moreStores.push(data);
          }
        }
        const moreChip = (
          <Tooltip
            title={
              <React.Fragment>
                {moreStoreTooltipList(moreStores)}
              </React.Fragment>
            }
          >
            <Chip
              size="small"
              variant="outlined"
              style={{ color: "#1760D2" }}
              label={stores.length - 2 + " more stores"}
            />
          </Tooltip>
        );
        limitedChips.push(moreChip);
        return limitedChips;
      }
    }
  };

  const moreStoreTooltipList = (stores) => {
    return (
      <ul>
        {stores.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    );
  };

  //const columnHelper = createColumnHelper(recipe.recipeList);

  const columns = [
    {
      accessorKey: "recipe_name",
      header: () => "Recipe Name",
      cell: (props) => {
        const { row } = props;
        return !row.depth ? (
          <Typography
            sx={{
              color: "#1760D2",
              textTransform: "uppercase",
            }}
          >
            {row.original.recipe_name}
          </Typography>
        ) : (
          <Stack direction="row" gap="12px" alignItems="center">
            <Avatar
              src={
                row.original.image
                  ? row.original.image
                  : "/static/images/avatar/1.jpg"
              }
              variant="rounded"
              sx={{ width: 44, height: 44 }}
            />
            <span className="link-text">{row.original.recipe_name}</span>
          </Stack>
        );
      },
    },
    {
      accessorKey: "type",
      header: () => "Type",
      cell: (props) => {
        const { row } = props;
        return !row.depth
          ? ""
          : row.original.type === "finishedproduct"
          ? "Finished Product"
          : "Recipe";
      },
    },
    {
      id: "status",
      accessorKey: "status",
      header: () => "Active",
      cell: (props) => {
        const { row } = props;
        return row.depth ? (
          row.original.status ? (
            <Chip
              size="small"
              color="primary"
              variant="filled"
              label="ACTIVE"
            />
          ) : (
            <Chip size="small" color="gray" label="INACTIVE" />
          )
        ) : (
          ""
        );
      },
    },
    {
      id: "store",
      header: () => "Store",
      cell: (props) => {
        const { row } = props;
        return row.depth ? (
          <Stack direction="row" gap="8px">
            {listStoreChipls(row.original.store)}
          </Stack>
        ) : (
          ""
        );
      },
    },
    {
      id: "actions",
      cell: (props) => {
        const { row } = props;
        return isEditable && row.depth ? (
          <SimpleMenu
            row={props.row}
            editAction={(e) => navigate(`edit/${e.id}`)}
            isEditable={isEditable}
            // archiveAction={(e) => {
            //   onArchiveRecipeCreate(e.id);
            // }}
          />
        ) : (
          ""
        );
      },
    },
  ];

  const breadcrumbs = [
    <Typography key="3" sx={{ fontSize: "14px", fontWeight: 400 }}>
      Recipe
    </Typography>,
  ];
  const sortingKey = [
    {
      id: "recipe_name",
      desc: false,
    },
  ];
  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        pageContent={
          <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
            <CustomTable
              pagination={pagination}
              searchText={search}
              defaultData={recipe.recipeList}
              columns={columns}
              headerText="Recipe"
              subRow={"subRows"}
              allRowsExpanded={true}
              isLoading={recipe.isLoading}
              onSearchChange={onSearchHandler}
              tableId={"recipe"}
              customRowStyle={(row) => {
                return !row.depth ? { borderBottom: "none" } : null;
              }}
              onRowLink={(e) => {
                return `details/${e.id}/?basic-details`;
              }}
              tableHeaderStyles={{
                position: "sticky",
                top: "0px",
                background: "#fff",
                zIndex: 99,
              }}
              tableStyle={{ overflow: "unset" }}
              // defaultSort={sortingKey}
              tableHeaderContent={
                <>
                  <Stack
                    direction={"row"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {ref?.current?.scrollLeft > 0 ? (
                      <Box
                        component={"img"}
                        height={20}
                        width={20}
                        src={LeftArrow}
                        onClick={() => onScrollRefClick("left")}
                        sx={{ cursor: "pointer" }}
                      />
                    ) : null}

                    <Stack
                      id="scroll-ele"
                      ref={ref}
                      sx={{
                        p: "6px",
                        flexDirection: "row",
                        gap: "6px",
                        overflow: "auto",
                        scrollBehavior: "smooth",
                      }}
                      className="scroll-hide"
                    >
                      {!selectChipCategory.isLoading &&
                      selectChipCategory.chipCategory &&
                      selectChipCategory.chipCategory.chipCategoryOptions &&
                      Array.isArray(
                        selectChipCategory.chipCategory.chipCategoryOptions
                      ) ? (
                        selectChipCategory.chipCategory.chipCategoryOptions.map(
                          (v, i) => {
                            const isSelected =
                              v.id ===
                              tableData?.recipeSelectedCategory?.chipCategoryId;
                            return (
                              <Stack
                                sx={{
                                  flexDirection: "row",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  selectChipCategory.chipCategoryValuesHandler(
                                    v
                                  );
                                }}
                              >
                                <SelectChip
                                  key={i}
                                  value={v}
                                  isSelected={isSelected}
                                  countKey="recipeListCount"
                                />
                              </Stack>
                            );
                          }
                        )
                      ) : (
                        <Loader />
                      )}
                    </Stack>

                    {ref?.current?.scrollWidth - ref?.current?.scrollLeft >
                    ref?.current?.clientWidth ? (
                      <Box
                        component={"img"}
                        height={20}
                        width={20}
                        src={ArrowRight}
                        onClick={() => onScrollRefClick("right")}
                        sx={{ cursor: "pointer" }}
                      />
                    ) : null}
                  </Stack>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      bgcolor: "#fff",
                    }}
                  />
                  <Stack
                    sx={{
                      flexDirection: "row",
                      p: "6px",
                      height: "55px",
                    }}
                  >
                    <InputControl
                      fullWidth
                      type="dropdown"
                      variant="filter"
                      size="small"
                      options={selectType.typeOptions}
                      getOptionLabel={(option) => option?.label || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      sx={{
                        minWidth: "20%",
                        maxWidth: "30%",
                        height: "36px",
                        mr: "6px",
                      }}
                      value={
                        selectType?.typeOptions?.find(
                          (option) =>
                            option.value === tableData.recipe.data.selectedType
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Type" />
                      )}
                      onChange={(event, option) =>
                        selectType.typeValueChangeHandler(option)
                      }
                    />

                    <InputControl
                      type="dropdown"
                      variant="filter"
                      size="small"
                      options={selectStatus.statusOptions}
                      getOptionLabel={(option) => option?.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      sx={{
                        minWidth: "150px",
                        mr: "18px",
                      }}
                      value={
                        selectStatus?.statusOptions?.find(
                          (option) =>
                            option.id === tableData.recipe.data.selectedStatus
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Status" />
                      )}
                      onChange={(event, option) =>
                        selectStatus.statusValueChangeHandler(option)
                      }
                    />
                    <InputControl
                      type="textfieldCheckbox"
                      label="Batch Group"
                      size="small"
                      value={tableData.recipe?.data?.batchGroup}
                      control={
                        <Checkbox
                          checked={tableData.recipe?.data?.batchGroup}
                          onChange={(e) =>
                            setBatchGroup(!tableData?.recipe?.data?.batchGroup)
                          }
                          sx={{ width: "18px", height: "18px" }}
                        />
                      }
                    />

                    <InputControl
                      type="dropdown"
                      variant="filter"
                      size="small"
                      multiple={true}
                      options={selectStore.listQuery.data ?? []}
                      value={tableData.recipe?.data?.storefilter}
                      disableCloseOnSelect
                      disableClearable={true}
                      onChange={(event, option) =>
                        StoreValuesHandler(event, option)
                      }
                      getOptionLabel={(option) => option?.name || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderTags={(value) => (
                        <LimitedTag value={value} autocompleteWidth="250px" />
                      )}
                      renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.id}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      filterOptions={filterOptions}
                      sx={{ width: "250px" }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Store" />
                      )}
                    />

                    <Button
                      type="button"
                      size="small"
                      variant="text"
                      sx={{
                        p: "6px 12px 6px 12px",
                        gap: "6px",
                        height: "36px",
                        mr: "6px",
                      }}
                      disabled={disableclearButton}
                      onClick={onClearFilter}
                    >
                      Clear All
                    </Button>
                  </Stack>
                </>
              }
              rightContent={
                <Stack direction="row" gap="8px">
                  {isCreateVisible && (
                    <Button
                      sx={{
                        height: "36px",
                        border: "1px solid #BCC7DB",
                        borderRadius: "10px",
                      }}
                      type="button"
                      size="medium"
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => navigate("create")}
                    >
                      Recipe
                    </Button>
                  )}
                </Stack>
              }
            />
          </Stack>
        }
      />

      <DeleteModal
        open={deleteInfo?.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />
    </>
  );
};
