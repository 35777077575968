import { useMemo } from "react";

export const useStatusSelect = ({ onChangeStatusHandler }) => {
  const statusOptions = useMemo(
    () => [
      { id: 1, name: "Active" },
      { id: 0, name: "Inactive" },
    ],
    []
  );

  const statusValueChangeHandler = (option) => {
    const selectedOption = option ? option.id : "";
    onChangeStatusHandler(selectedOption);
  };
  return { statusOptions, statusValueChangeHandler };
};
