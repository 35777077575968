import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { getRecipeHowToPrepare } from "../api";

export const useHowToPrepareQuries = ({ recipeId, isHowtoPrepare }) => {
  const queryKey = useMemo(
    () =>
      recipeId && isHowtoPrepare ? `recipe-how-to-prepare-${recipeId}` : null,
    [recipeId, isHowtoPrepare]
  );

  const howToPrepareQuery = useQuery({
    queryKey: queryKey ? [queryKey] : null,
    queryFn: () => getRecipeHowToPrepare({ recipeId }),
    enabled: !!queryKey,
    onError: (error) => {
      console.error("Error fetching preparation data:", error);
    },
  });

  return { howToPrepareQuery };
};
