import * as React from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { InputControl, Loader } from "../../../shared";
import { AsyncPaginate } from "react-select-async-paginate";

export const RecipeLabelCreateOrEdit = ({
  handleCreateOrEditModal,
  onCreateSubmit,
  labelPrinting,
  isLabelprintingList,
  globalData,
  labelPrintingEditTypeChangeHandler,
  labelPrintingEditChangeHandler,
  onEditChange,
  validator,
  basicDetailsQuery,
  labelPrintingDetails,
  handleIngredientList,
  recipeListQuery,
  labelPrintingRecipeEditChangeHandler,
}) => {
  const {
    defrosting_time,
    freezer_life,
    item_id,
    item_name,
    type_id,
    variant_name,
  } = labelPrinting?.editableData;

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px solid #E6E8F0",
      height: "48px",
    }),
    option: (provided, state) => ({
      ...provided,
      maxHeight: "239px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Stack
        ref={innerRef}
        {...innerProps}
        sx={{
          flexDirection: "row",
          p: "7px 8px",
          gap: "2px",
          "&:hover": {
            backgroundColor: "#edf3fc",
          },
        }}
      >
        <Stack
          sx={{
            gap: "2px",
          }}
        >
          <Typography variant="subtitle2" fontWeight={400} lineHeight="17px">
            {data.label}
          </Typography>
        </Stack>
      </Stack>
    );
  };
  return labelPrintingDetails?.isPending && labelPrinting?.id ? (
    <Loader />
  ) : (
    <>
      <Stack
        justifyContent="space-between"
        height="100%"
        gap="12px"
        maxHeight={"calc(100% - 24px)"}
        mt="32px"
      >
        <InputControl
          type="dropdown"
          required
          fullWidth
          disabled={!isLabelprintingList}
          options={globalData?.labelPrinting_types ?? []}
          value={
            globalData?.labelPrinting_types?.find(
              (value) => value.value === type_id
            ) || null
          }
          onChange={(event, option) =>
            labelPrintingEditTypeChangeHandler("type_id", option)
          }
          getOptionLabel={(option) => option?.label || ""}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Type"
              error={validator.current.message("Type", type_id, "required")}
              helperText={validator.current.message(
                "Type",
                type_id,
                "required"
              )}
            />
          )}
        />
        {type_id === 3 ? (
          <InputControl
            disabled={!isLabelprintingList}
            type="number"
            required
            name="item_name"
            sx={{
              selfAlign: "right",
              width: "100%",
            }}
            value={item_name}
            onChange={(e) => {
              onEditChange(e);
            }}
            label="Item Name"
            error={validator.current.message(
              "Item Name",
              item_name,
              "required"
            )}
            helperText={validator.current.message(
              "Item Name",
              item_name,
              "required"
            )}
          />
        ) : isLabelprintingList ? (
          type_id === 2 ? (
            <AsyncPaginate
              debounceTimeout={1000}
              placeholder="Item Name *"
              menuPlacement="bottom"
              components={{
                Option: CustomOption,
              }}
              value={
                labelPrinting?.editableData?.item_id
                  ? {
                      value: labelPrinting?.editableData?.item_name,
                      label: labelPrinting?.editableData?.item_name,
                    }
                  : ""
              }
              loadOptions={async (e, p, { page }) => {
                return await handleIngredientList({
                  search: e,
                  page,
                  type: "item_id",
                });
              }}
              onChange={(e) => {
                labelPrintingEditChangeHandler(
                  {
                    target: {
                      name: "item_id",
                      value: e.value || null,
                    },
                  },
                  e
                );
              }}
              additional={{
                page: 1,
              }}
              styles={customStyles}
            />
          ) : (
            <InputControl
              type="dropdown"
              disabled={!isLabelprintingList}
              fullWidth
              required
              options={recipeListQuery?.data
                ?.slice()
                .sort((a, b) => a.name.localeCompare(b.name))}
              value={
                recipeListQuery?.data?.find(
                  (item) => item.id === Number(item_id)
                ) || null
              }
              onChange={(event, option) =>
                labelPrintingRecipeEditChangeHandler("item_id", option)
              }
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                minWidth: "130px",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Item Name"
                  error={validator.current.message(
                    "Item Name",
                    item_id,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "Item Name",
                    item_id,
                    "required"
                  )}
                />
              )}
            />
          )
        ) : (
          <InputControl
            disabled={!isLabelprintingList}
            type="number"
            required
            name="item_name"
            sx={{
              selfAlign: "right",
              width: "100%",
            }}
            value={basicDetailsQuery?.data?.name}
            label="Item Name"
            error={validator.current.message(
              "Item Name",
              basicDetailsQuery?.data?.name,
              "required"
            )}
            helperText={validator.current.message(
              "Item Name",
              basicDetailsQuery?.data?.name,
              "required"
            )}
          />
        )}

        <InputControl
          name="variant_name"
          label="Variant Name"
          onChange={(e) => {
            onEditChange(e);
          }}
          value={variant_name}
          sx={{
            width: "100%",
            bgcolor: "#fff",
          }}
        />
        <InputControl
          name="defrosting_time"
          label="Defrosting Time Hrs"
          onChange={(e) => {
            onEditChange(e);
          }}
          value={defrosting_time}
          sx={{
            width: "100%",
            bgcolor: "#fff",
          }}
        />
        <InputControl
          name="freezer_life"
          label="Coolroom/Freezer Life Hrs"
          onChange={(e) => {
            onEditChange(e);
          }}
          value={freezer_life}
          sx={{
            width: "100%",
            bgcolor: "#fff",
          }}
        />
        <Stack direction="row" gap={1} justifyContent="flex-end">
          <Button
            sx={{ height: "40px", width: "33px", alignSelf: "end" }}
            variant="outlined"
            onClick={() => {
              handleCreateOrEditModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{ height: "40px", width: "33px", alignSelf: "end" }}
            variant="contained"
            onClick={onCreateSubmit}
          >
            {false ? "Update" : "Save"}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
