import {
  useQueryClient,
  useQuery,
  useMutation,
  keepPreviousData,
} from "@tanstack/react-query";
import qs from "qs";
import {
  ErrorFormatter,
  errorMessage,
  successMessage,
} from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import {
  editLabelPrinting,
  getLabelPrintingById,
  getLabelPrintingList,
  removeLabelPrinting,
  saveLabelPrinting,
} from "../api/LabelPrintingApi";
import { getIngredientList } from "../../Ingredients/api/IngredientApi";
import { getRecipeList } from "../../Recipe/api";
import { useRecipeKeys } from "../../Recipe/hooks";

const labelPrintingKeys = {
  all: ["labelPrinting"],
  lists: () => [...labelPrintingKeys.all, "labelPrintinglist"],
  labelPrintinglist: (filters) => [...labelPrintingKeys.lists(), filters],
  detail: (id) => [...labelPrintingKeys.labelPrintinglist(), id],
};
const ingredientKeys = {
  all: ["ingredient"],
  lists: () => [...ingredientKeys.all, "ingredientlist"],
};
export const useLabelPrintingQueries = ({
  isList,
  pagination,
  id,
  resetDeleteInfo,
  type_id,
  item_id,
  sorting,
  debouncedText,
  moduleSorting,
  typeId,
  isLabelprintingList,
  setLabelPrinting,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { recipeKeys } = useRecipeKeys();

  let params = {};
  if (debouncedText) {
    params = { ...params, search: debouncedText };
  }
  if (type_id !== "" || typeId !== "") {
    params = { ...params, type_id: type_id ? type_id : typeId };
  }
  if (item_id !== "") {
    params = { ...params, item_id: item_id };
  }

  if (sorting?.length > 0) {
    const sortByParams = sorting.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
      {}
    );

    params = {
      ...params,
      sort: sortByParams,
    };
  }
  if (moduleSorting?.length > 0) {
    const sortByParams = moduleSorting.reduce(
      (obj, item) =>
        Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
      {}
    );

    params = {
      ...params,
      sort: sortByParams,
    };
  }
  const query = {
    params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  };

  //list query
  const listQuery = useQuery({
    queryKey: labelPrintingKeys.labelPrintinglist({
      query,
      pagination,
    }),
    queryFn: () =>
      getLabelPrintingList({
        query,
        pagination,
      }),
    placeholderData: keepPreviousData,
    enabled: isList,
  });
  const handleIngredientList = async ({ page, search }) => {
    let params = {};
    let pagination = {
      pageIndex: page || 1,
      pageSize: 10,
    };

    if (search) {
      params = { ...params, search }; // Add search filter if provided
    }

    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };

    try {
      const res = await getIngredientList({ query, pagination });
      if (res) {
        const options = res.ingredients
          .map((data) => ({
            value: data.id,
            label: data.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        return {
          options,
          hasMore: res.pagination.hasMorePages, // Set `hasMore` to handle infinite scroll
          additional: {
            page: page && page + 1, // Increment the page for the next API call
          },
        };
      }
    } catch (err) {
      return {
        options: [],
        hasMore: false, // Stop loading on error
        additional: {
          page: page && page, // Maintain the same page in case of error
        },
      };
    }
  };
  const ingredientListQuery = useQuery({
    queryKey: ingredientKeys.lists(),
    queryFn: () => getIngredientList({ query, pagination }),
    placeholderData: keepPreviousData,
    enabled: isLabelprintingList,
  });

  const recipeListQuery = useQuery({
    queryKey: recipeKeys?.recipelist({}),
    queryFn: () => getRecipeList({}),
    placeholderData: keepPreviousData,
    enabled: isLabelprintingList,
  });
  const createLabelPrinting = useMutation({
    mutationFn: ({ _, data }) => saveLabelPrinting({ items: data }),
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: labelPrintingKeys.lists(),
      });
      setLabelPrinting((draft) => {
        draft.data = [
          {
            type_id: "",
            item_id: "",
            other_item: "",
            variant_name: "",
            defrosting_time: "",
            freezer_life: "",
          },
        ];
        draft.editableData = {
          type_id: "",
          item_id: "",
          other_item: "",
          variant_name: "",
          defrosting_time: "",
          freezer_life: "",
          item_id: "",
        };
      });
      successMessage("Label printing created successfully!!");
      return variables.handleCreateOrEditModal();
    },
  });
  const labelPrintingDetails = useQuery({
    queryKey: labelPrintingKeys.detail(id),
    queryFn: () => getLabelPrintingById(id),
    enabled: id > 0,
  });
  const updateLabelPrinting = useMutation({
    mutationFn: ({ _, data }) => editLabelPrinting({ items: data }, id),
    onError: (error) => {
      errorMessage(ErrorFormatter(error.response.data.errors));
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: labelPrintingKeys.lists(),
      });
      setLabelPrinting((draft) => {
        draft.data = [
          {
            type_id: "",
            item_id: "",
            other_item: "",
            variant_name: "",
            defrosting_time: "",
            freezer_life: "",
          },
        ];
        draft.editableData = {
          type_id: "",
          item_id: "",
          other_item: "",
          variant_name: "",
          defrosting_time: "",
          freezer_life: "",
          item_id: "",
        };
      });
      successMessage("Label printing updated successfully");
      return variables.handleCreateOrEditModal();
    },
  });
  const deleteLabelPrinting = useMutation({
    mutationFn: removeLabelPrinting,
    onError: (error) => {
      errorMessage(error?.response?.data?.error ?? "Error");
    },
    onSuccess: (res, variables) => {
      if (res.success) {
        queryClient.invalidateQueries({
          queryKey: labelPrintingKeys.lists(),
        });
        successMessage(res.message);
        variables.to && navigate(variables.to);
        resetDeleteInfo();
      } else if (res.success === false) {
        errorMessage(res.message);
      }
    },
  });
  return {
    listQuery,
    ingredientListQuery,
    recipeListQuery,
    createLabelPrinting,
    labelPrintingDetails,
    updateLabelPrinting,
    deleteLabelPrinting,
    handleIngredientList,
  };
};
