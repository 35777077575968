import {
  Box,
  Chip,
  Link,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import { CustomTabPanel, DeleteModal, PageListLayout } from "../../../shared";
import { useIngredients } from "../hooks/UseIngredient";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUnits } from "../../MasterData/hooks";
import { IngredientsBasicDetails } from "../components/IngredientsBasicDetails";
import { IngredientsLabels } from "../components/IngredientsLabels";
import { useLabelPrinting } from "../../LabelPrinting/hooks/useLabelPrinting";

export const IngredientDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { isVisible } = useLabelPrinting({ isList: true });
  const {
    ingredient,
    confirmDelete,
    onDelete,
    deleteInfo,
    resetDeleteInfo,
    state,
    isEditable,
    isDeletable,
  } = useIngredients({
    detId: id,
    isIngredientlist: false,
    isTaxgrouplist: false,
    isTaglist: false,
    isList: false,
  });
  const { listQueryData } = useUnits({ list: true });
  const optionsUnits = listQueryData?.data?.units
    ?.filter((i) => i?.ignore === 0)
    ?.map((item) => ({
      name: item.symbol,
      id: item.id,
    }));
  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate("/ingredients")}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Ingredients
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      {ingredient?.data?.name}
    </Typography>,
  ];

  const handleChange = (event, newValue) => {
    navigate(`/ingredients/details/${id}${newValue}`);
  };

  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        headerText={
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              height="62px"
              bgcolor="white"
              sx={{
                position: "sticky",
                zIndex: "99px",
                top: "0px",
              }}
            >
              <Stack alignSelf="center" direction="row" gap={2} ml={3}>
                <Typography variant="h6" fontWeight={600} fontSize="24px">
                  {ingredient?.data?.name}
                </Typography>
                <Box mt={0.5} justifyContent="space-between">
                  {ingredient?.data?.status ? (
                    <Chip
                      size="small"
                      color="primary"
                      variant="filled"
                      label="ACTIVE"
                      sx={{ height: "22px" }}
                    />
                  ) : (
                    <Chip size="small" color="gray" label="INACTIVE" />
                  )}
                </Box>
              </Stack>
            </Stack>
          </>
        }
        rightContent={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            {isEditable && (
              <img
                alt="edit"
                onClick={(e) => navigate(`/ingredients/edit/${id}`)}
                src="/icons/ic_edit.png"
                style={{ cursor: "pointer" }}
              />
            )}
            {isDeletable && (
              <img
                alt="delete"
                onClick={() => onDelete(id, "/ingredients")}
                src="/icons/ic_trash_sm.png"
                style={{ cursor: "pointer" }}
              />
            )}
          </Stack>
        }
        pageContent={
          <>
            <Box
              className="sticky-Tab"
              zIndex={100}
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                px: "16px",
                bgcolor: "#fff",
                top: "62px",
              }}
            >
              <Tabs value={search} onChange={handleChange}>
                <Tab label="Basic Details" value={""} />
                {isVisible && <Tab label="Labels" value={"?labels"} />}
              </Tabs>
            </Box>

            <CustomTabPanel value={search} index={""}>
              <IngredientsBasicDetails
                state={state}
                ingredient={ingredient}
                optionsUnits={optionsUnits}
                isEditable={isEditable}
                isDeletable={isDeletable}
                id={id}
                onDelete={onDelete}
              />
            </CustomTabPanel>
            <CustomTabPanel value={search} index={"?labels"}>
              <IngredientsLabels
                state={state}
                ingredient={ingredient}
                optionsUnits={optionsUnits}
                isEditable={isEditable}
                isDeletable={isDeletable}
                ingredientId={id}
                onDelete={onDelete}
              />
            </CustomTabPanel>
          </>
        }
      />
      <DeleteModal
        open={deleteInfo.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />
    </>
  );
};
