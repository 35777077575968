import { useImmer } from "use-immer";
import { useHowToPrepareQuries } from "./useHowToPrepareQuries";

export const useHowToPrepare = ({ recipeId, isHowtoPrepare }) => {
  const [howToPrepare, setHowToPrepare] = useImmer({
    open: false,
    items: [],
  });

  const { howToPrepareQuery } = useHowToPrepareQuries({
    recipeId,
    isHowtoPrepare,
  });

  const handleVideoClick = (type, item) => {
    setHowToPrepare((draft) => {
      draft.open = type;
      draft.items = item;
    });
  };
  const onImageClick = (item) => {
    setHowToPrepare((draft) => {
      draft.items = item;
    });
  };

  return { howToPrepareQuery, howToPrepare, handleVideoClick, onImageClick };
};
