import { useImmer } from "use-immer";
import React, { useRef, useState, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useDebounce } from "use-debounce";
import {
  useDelete,
  useStoreSelectQueries,
  useStatusSelect,
  usePermission,
} from "../../../shared";
import { useAdminUsersQueries } from "./useAdminUsersQueries";
import { AppContext } from "../../../store";

const tableName = "adminUsers";

export const useAdminUsers = ({ isList, userId, isAdd }) => {
  const isCreateVisible = usePermission("user-add");
  const isDeletable = usePermission("user-delete");
  const isRoleDeletable = usePermission("role-delete");
  const isRoleCreateVisible = usePermission("role-create");
  const isRoleEditable = usePermission("role-modify");
  const [, forceUpdate] = useState();

  // App context
  const {
    tableData,
    setPagination,
    setTableState,
    setSearchState,
    clearAllFilters,
  } = React.useContext(AppContext);

  // Table context
  const {
    pagination,
    search,
    data: { storeFilter, status },
  } = tableData[tableName];

  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const { deleteInfo, onDelete, resetDeleteInfo } = useDelete();

  const [state, setState] = useImmer({
    data: { name: "", id: "" },
    display_name: "",
    addData: {
      user_id: null,
      role_id: null,
    },
    editData: {
      role_id: null,
    },
    open: false,
    id: "",
    filter: {
      search: search ?? "",
    },
    addRoleOpen: false,
    deleteRoleOpen: false,
    selectedRole: null,
    openDialog: false,
    isDialogOpen: false,
    mac_security: 0,
    selectedStatus: status ?? "",
    filters: {
      storefilter: storeFilter ?? [],
      status: "",
      all: [],
    },
  });

  const [debouncedText] = useDebounce(state?.filter?.search, 1000);

  const {
    listQuery,
    userListQuery,
    createUser,
    userDetails,
    deleteUser,
    deleteRoleFromUser,
    rolesListQuery,
    changeMacIdSecurityStatus,
  } = useAdminUsersQueries({
    isList,
    pagination,
    // sorting,
    userId: state?.id ? state?.id : userId,
    resetDeleteInfo,
    debouncedText,
    filterApplied: state?.filters.storefilter,
    status: state.selectedStatus,
    isAdd,
  });

  /**
   * Handles changes to the selected status and updates the state and table context.
   *
   * This function updates the `selectedStatus` in the component's state
   * based on the provided value and also updates the table context state
   * with the new status.
   *
   * @param {string} value - The new status value to set.
   * @returns {void} This function does not return a value.
   */
  const onChangeStatusHandler = (value) => {
    setState((draft) => {
      draft.selectedStatus = value;
    });
    updateTableContextState({ key: "status", value });
  };

  const { listQuery: storeLists } = useStoreSelectQueries({
    storeList: !!isList,
  });

  const selectStatus = useStatusSelect({ onChangeStatusHandler });

  /**
   * Updates the table context state with the specified key and value.
   *
   * This function sets the state of the table with the provided key and value,
   * allowing for updates to specific properties of the table context.
   *
   * @param {Object} params - The parameters for updating the table state.
   * @param {string} params.key - The key of the state property to update.
   * @param {*} params.value - The new value to set for the specified key.
   * @returns {void} This function does not return a value.
   */
  const updateTableContextState = ({ key, value }) => {
    const updateContextFunction =
      key === "search" ? setSearchState : setTableState;

    updateContextFunction({
      tableName,
      key,
      value,
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.addData[name] = value;
    });
  };

  const onSaveUser = (data) => {
    const payload = {
      user_id: state?.addData?.user_id,
    };
    createUser.mutate({ data: payload, roleID: state?.addData?.role_id });
  };

  const onSubmit = () => {
    if (validator.current.allValid()) {
      onSaveUser();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  useEffect(() => {
    if (userDetails?.data) {
      setState((draft) => {
        draft.data.name = userDetails?.data?.name;
        draft.data.id = Number(userDetails?.data?.id);
        draft.mac_security = userDetails?.data?.mac_security;
      });
    }
  }, [userDetails.data]);

  const confirmDelete = () => {
    deleteUser.mutate({ id: deleteInfo.id });
  };

  const removeRoleFromUser = () => {
    const payload = {
      user_id: [userId],
    };
    deleteRoleFromUser.mutate({
      Id: state.selectedRole,
      data: payload,
      handleModal: () => handleModal("delete-role", false, null),
    });
  };

  const onSearchHandler = (search) => {
    setState((draft) => {
      draft.filter.search = search;
    });
  };

  /**
   * Handles the selection of store values and updates the state and table context.
   *
   * This function processes the selected options, updates the `storefilter`
   * in the state with the selected items, and resets the `status` filter.
   *
   * @param {Event} event - The event object from the selection change.
   * @param {Array<Object>} [option] - An array of selected option objects.
   * @returns {void} This function does not return a value.
   */
  const StoreValuesHandler = (event, option) => {
    const storeFilters = option?.map((item) => {
      return { ...item };
    });

    setState((draft) => {
      draft.filters.storefilter = storeFilters;
      draft.filters.status = "";
    });

    // Update cached store filter
    updateTableContextState({ key: "storeFilter", value: storeFilters });
  };

  /**
   * Clears the selected status and store filters in the state.
   *
   * This function resets the `selectedStatus` to an empty string
   * and clears the `storefilter` array. It also updates the
   * table context state to reflect these changes.
   *
   * @function
   * @returns {void} This function does not return a value.
   */
  const onClearFilter = () => {
    setState((draft) => {
      draft.selectedStatus = "";
      draft.filters.storefilter = [];
    });

    // Rest cached filter state
    clearAllFilters({ tableName });
  };

  const disableclearButton =
    state.selectedStatus === "" &&
    state?.filters?.status === "" &&
    Array.isArray(state.filters.storefilter) &&
    state.filters.storefilter.length === 0
      ? true
      : false;

  const handleModal = (type, state, data, display_name) => {
    setState((draft) => {
      draft.display_name = display_name;
    });
    switch (type) {
      case "add-role":
        setState((draft) => {
          draft.addRoleOpen = state;
          draft.editData.role_id = "";
        });
        break;
      case "delete-role":
        setState((draft) => {
          draft.deleteRoleOpen = state;
          draft.selectedRole = data;
        });
        break;
    }
  };

  const onRoleAssign = () => {
    const payload = {
      user_id: state?.data?.id,
    };
    createUser.mutate({
      data: payload,
      roleID: state?.editData?.role_id,
      handleModal,
    });
  };

  const onChangeRole = (e) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.editData[name] = value;
    });
  };

  const handleSecurityDialogOpen = (type) => {
    setState((draft) => {
      draft.isDialogOpen = type;
    });
  };

  const handleDisableMacid = (e) => {
    const { checked } = e?.target;
    setState((draft) => {
      draft.mac_security = checked ? 1 : 0;
    });
    onSubmitMacidSecurity();
  };

  const onSubmitMacidSecurity = () => {
    if (validator.current.allValid()) {
      changeMacIdSecurityStatus.mutate({
        id: userId,
        handleModal: () => handleSecurityDialogOpen(false),
      });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  // Function to handle table pagination
  const handlePagination = (callback) => setPagination({ tableName, callback });

  // Caches the search value in the table context state whenever the debounced text changes.
  React.useEffect(() => {
    updateTableContextState({ key: "search", value: debouncedText });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText]);

  return {
    state,
    pagination,
    handlePagination,
    listQuery,
    userListQuery,
    onChange,
    validator,
    onSubmit,
    onDelete,
    deleteInfo,
    resetDeleteInfo,
    confirmDelete,
    onSearchHandler,
    storeLists,
    StoreValuesHandler,
    selectStatus,
    onClearFilter,
    disableclearButton,
    createUser,
    userDetails,
    handleModal,
    onRoleAssign,
    onChangeRole,
    rolesListQuery,
    removeRoleFromUser,
    isCreateVisible,
    handleDisableMacid,
    handleSecurityDialogOpen,
    onSubmitMacidSecurity,
    isDeletable,
    isRoleCreateVisible,
    isRoleDeletable,
    isRoleEditable,
  };
};
