import { Avatar, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { memo } from "react";
import { VideoPlayer } from "../../../shared/components/VideoPlayer";
import { useHowToPrepare } from "../hooks";
import { CustomeDialog, Loader } from "../../../shared";
import parse from "html-react-parser";
import emptyIcon from "../../../assets/images/Icons/search-table.png";
import PreparationStepsDialog from "./PreparationStepsDialog";
import { useLocation } from "react-router-dom";

export const HowToPrepareDetails = ({ recipeId }) => {
  const { search } = useLocation();
  const isHowtoPrepare = search.includes("prepare");
  const { howToPrepareQuery, handleVideoClick, howToPrepare, onImageClick } =
    useHowToPrepare({ recipeId, isHowtoPrepare });

  if (howToPrepareQuery.isLoading) {
    return <Loader />;
  }

  const preparationSteps = (steps) =>
    steps.map((item, index) => (
      <div key={index}>
        <Stack
          sx={{
            flexDirection: "row",
            gap: "22px",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              height: "48px",
              width: "48px",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            variant="rounded"
            src={item.image_url}
            onClick={() => handleVideoClick(true, item)}
          />
          <Stack>
            <Typography color="#657083">STEP {item.step_number}</Typography>
            <Typography>{parse(item.step)}</Typography>
          </Stack>
        </Stack>
      </div>
    ));

  const EmptyComponent = ({ message }) => {
    return (
      <Stack alignItems="center" gap="8px">
        <img src={emptyIcon} alt="empty" height={56} width={56} />
        <Typography
          sx={{ fontWeight: 400, fontSize: "14px", color: "#485364" }}
        >
          {message ?? "No matches found"}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack padding={2}>
      <Typography variant="subtitle1" sx={{ fontSize: "20px" }}>
        Method
      </Typography>
      <Divider />
      {howToPrepareQuery?.data?.method &&
      howToPrepareQuery?.data?.method !== "<p><br></p>" ? (
        <Stack
          sx={{
            borderRadius: "4px",
            bgcolor: "#F7FAFC",
            padding: "22px",
            gap: "16px",
          }}
        >
          {parse(
            howToPrepareQuery?.data?.method
              ? howToPrepareQuery?.data?.method
              : ""
          )}
        </Stack>
      ) : (
        <EmptyComponent message={"No method added yet!"} />
      )}

      <Stack gap="10px" marginTop="12px">
        <Typography variant="subtitle1" sx={{ fontSize: "20px" }}>
          Recipe Preparation Video
        </Typography>
        <Divider />
        {howToPrepareQuery?.data?.videos?.length ? (
          <Grid container spacing={2}>
            {howToPrepareQuery?.data?.videos?.map((video, index) => {
              return (
                <Grid item xs={6} key={index}>
                  <VideoPlayer video_url={video?.video_url} />
                  {/* <Typography variant="subtitle1">{video.description}</Typography> */}
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <EmptyComponent message={"No video added yet!"} />
        )}

        <Stack marginTop="12px">
          <Typography variant="h6">Preparation Steps</Typography>
          <Divider />
          <Stack gap="16px" padding="10px">
            {howToPrepareQuery?.data?.steps?.length ? (
              preparationSteps(howToPrepareQuery?.data?.steps)
            ) : (
              <EmptyComponent message={"No steps added yet!"} />
            )}
          </Stack>
        </Stack>
      </Stack>
      <CustomeDialog
        PaperProps={{
          sx: {
            width: "460px",
          },
        }}
        closeIcon={false}
        open={howToPrepare?.open}
        content={
          <PreparationStepsDialog
            state={howToPrepare.items}
            handleClose={handleVideoClick}
            data={howToPrepareQuery?.data?.steps}
            onImageClick={onImageClick}
          />
        }
      />
    </Stack>
  );
};
