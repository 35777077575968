import { Box, Typography, IconButton, Paper, Stack } from "@mui/material";
import React, { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import parse from "html-react-parser";

function PreparationStepsDialog({ handleClose, state, data, onImageClick }) {
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -150,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 150,
        behavior: "smooth",
      });
    }
  };

  return (
    <Paper>
      <Box position="relative">
        <IconButton
          onClick={() => handleClose(false, [])}
          sx={{
            height: "20px",
            width: "20px",
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 10,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
          }}
        >
          <CloseIcon style={{ color: "#000", fontSize: "20px" }} />
        </IconButton>

        <Box
          component="img"
          src={state?.image_url}
          sx={{ width: "100%", height: "250px", objectFit: "fill" }}
        />

        <Box p={2}>
          <Typography fontSize="16px" fontWeight={600}>
            Step {state?.step_number}:
          </Typography>
          <Typography
            variant="body1"
            fontSize="14px"
            fontWeight={400}
            lineHeight="16.94px"
          >
            {state?.step
              ? parse(state.step)
              : "Step description not available."}
          </Typography>
        </Box>

        <Box px={2} py={1} position="relative">
          <IconButton
            onClick={scrollLeft}
            sx={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 10,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <ChevronLeftIcon />
          </IconButton>

          <Stack
            ref={scrollContainerRef}
            className="scroll-hide"
            direction={"row"}
            sx={{
              display: "flex",
              gap: 3,
              overflowX: "auto",
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
          >
            {data.map((item) => (
              <Box
                key={item.id}
                onClick={() => onImageClick(item)}
                sx={{
                  cursor: "pointer",
                  border:
                    item.step_number === state.step_number
                      ? "2px solid #1976d2"
                      : "none",
                  borderRadius: "4px",
                  overflow: "hidden",
                  position: "relative",
                  width: "150px",
                  flexShrink: 0,
                  transition: "transform 0.3s ease-in-out",
                  transform:
                    item.step_number === state.step_number
                      ? "scale(1.2)"
                      : "none",
                }}
              >
                <Box
                  component="img"
                  src={item.image_url}
                  alt={item.step_number}
                  sx={{
                    width: "100%",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />

                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    color: "#fff",
                    padding: "10px 8px",
                    fontSize: "14px",
                  }}
                >
                  Step: {item.step_number}
                </Box>
              </Box>
            ))}
          </Stack>

          <IconButton
            onClick={scrollRight}
            sx={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 10,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
}

export default PreparationStepsDialog;
