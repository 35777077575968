import { useDebounce } from "use-debounce";
import { useRecipeListQueries } from "./useRecipeListQueries";
import { useImmer } from "use-immer";
import { useContext, useEffect } from "react";
import {
  useCategorySelectChip,
  useDelete,
  usePermission,
  useStatusSelect,
  useStoreSelect,
  useTypeSelect,
} from "../../../shared";
import { useRecipeDelete } from "./useRecipeDelete";
import { createFilterOptions } from "@mui/material";
import { AppContext } from "../../../store";

export const useRecipeList = ({ recipelist = false }) => {
  const isDeletable = usePermission("recipe-delete");
  const isCreateVisible = usePermission("recipe-create");
  const isEditable = usePermission("recipe-modify");

  const [recipe, setRecipe] = useImmer({
    search: "",
    recipeList: [],
    isLoading: true,
    batchGroup: false,
    selectedType: "",
    selectedStatus: 1,
    chipCategoryId: 0,
    selectedStore: 0,
    filters: {
      storefilter: [],
    },
  });
  const moduleName = "recipe";
  const {
    setTableState,
    tableData,
    clearAllFilters,
    setSorting,
    setPagination,
    setSearchState,
  } = useContext(AppContext);
  const { search } = tableData[moduleName];
  const { deleteInfo, onDelete, resetDeleteInfo } = useDelete();

  const { mutateDeleteRecipe } = useRecipeDelete({ resetDeleteInfo });

  const [debouncedText] = useDebounce(search, 1000);

  const selectStore = useStoreSelect({ storelist: true });

  const onChangeCategoryHandler = (value) => {
    setSearchState({
      tableName: "recipeSelectedCategory",
      key: "chipCategoryId",
      value:
        tableData.recipeSelectedCategory.chipCategoryId === value ? 0 : value,
    });
  };
  const selectChipCategory = useCategorySelectChip({
    categorylist: true,
    onChangeCategoryHandler,
  });

  const onChangeStatusHandler = (value) => {
    setTableState({
      tableName: moduleName,
      key: "selectedStatus",
      value: value,
    });
  };
  const selectStatus = useStatusSelect({ onChangeStatusHandler });

  const onChangeTypeHandler = (value) => {
    setTableState({
      tableName: moduleName,
      key: "selectedType",
      value: value,
    });
  };

  const selectType = useTypeSelect({ onChangeTypeHandler });
  const setBatchGroup = (value) => {
    setTableState({
      tableName: moduleName,
      key: "batchGroup",
      value: value,
    });
  };

  useEffect(() => {
    if (
      selectChipCategory.chipCategory.initialSelectedItem > 0 &&
      tableData?.recipeSelectedCategory?.chipCategoryId < 1
    ) {
      setSearchState({
        tableName: "recipeSelectedCategory",
        key: "chipCategoryId",
        value: selectChipCategory.chipCategory.initialSelectedItem,
      });
      setRecipe((draft) => {
        draft.chipCategoryId =
          selectChipCategory.chipCategory.initialSelectedItem;
      });
    }
  }, [
    selectChipCategory.chipCategory.initialSelectedItem,
    tableData.recipeSelectedCategory.chipCategoryId,
  ]);

  const { listQuery } = useRecipeListQueries({
    recipelist,
    debouncedText,
    category_id: tableData?.recipeSelectedCategory.chipCategoryId,
    type: tableData?.recipe?.data?.selectedType,
    batch_group: tableData?.recipe.data?.batchGroup,
    status: tableData?.recipe?.data?.selectedStatus,
    filterApplied: tableData?.recipe?.data?.storefilter,
  });

  useEffect(() => {
    if (listQuery.data) {
      setRecipe((draft) => {
        draft.isLoading = true;
      });

      const recipes = [];

      listQuery.data.forEach((item) => {
        if (!recipes.find((ri) => ri.id === item.subcategory_id)) {
          recipes.push({
            id: item.subcategory_id,
            recipe_name: item.subcategory_name,
            subcategory_display_order: item.subcategory_display_order,
            subRows: [],
          });
        }
      });

      if (recipes.length > 0) {
        listQuery.data.forEach((item) => {
          const findRecipe = recipes.find(
            (ri) => ri.id === item.subcategory_id
          );
          if (findRecipe) {
            findRecipe.subRows.push({
              id: item.id,
              recipe_name: item.name,
              image: item.image,
              status: item.status,
              store: item.stores,
              type: item.type,
            });
          }
        });
      }

      recipes.sort(
        (a, b) => a.subcategory_display_order - b.subcategory_display_order
      );

      setRecipe((draft) => {
        draft.recipeList = recipes;
        draft.isLoading = false;
      });
    }
  }, [listQuery.data]);

  const onSearchHandler = (search) => {
    setSearchState({
      tableName: moduleName,
      key: "search",
      value: search,
    });
  };

  const onClearSearch = () => {
    setRecipe((draft) => {
      draft.search = "";
    });
  };

  const onClearFilter = () => {
    clearAllFilters({ tableName: moduleName });
  };

  const confirmDelete = () => {
    mutateDeleteRecipe.mutate(deleteInfo.id);
  };

  const StoreValuesHandler = (event, option) => {
    const item = option?.map((item) => {
      return { ...item };
    });
    setTableState({
      tableName: moduleName,
      key: "storefilter",
      value: item,
    });
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.name,
  });

  const disableclearButton =
    tableData.recipe.data.batchGroup === false &&
    !tableData.recipe.data.selectedType &&
    !tableData.recipe.data.selectedStatus &&
    tableData.recipe.data.storefilter.length === 0
      ? true
      : false;

  return {
    listQuery,
    recipe,
    selectType,
    selectStatus,
    selectChipCategory,
    selectStore,
    setBatchGroup,
    onSearchHandler,
    onClearFilter,
    onChangeTypeHandler,
    setPagination,
    deleteInfo,
    onDelete,
    resetDeleteInfo,
    confirmDelete,
    setSorting,
    disableclearButton,
    onClearSearch,
    StoreValuesHandler,
    filterOptions,
    isCreateVisible,
    isEditable,
    tableData,
    moduleName,
    search,
  };
};
