import { useQuery } from "@tanstack/react-query";
import { getGlobalData } from "../api";
import { useImmer } from "use-immer";
import { useEffect } from "react";

const bootKeys = {
  all: ["boot"],
  lists: () => [...bootKeys.all, "list"],
};

const initialState = {
  ingredients: {
    data: {
      selectedStatus: null,
      tagslist: [],
      types: [],
    },
    sorting: [],
    pagination: {
      pageIndex: 1,
      pageSize: 50,
    },
    search: "",
  },
  recipe: {
    data: {
      selectedType: null,
      selectedStatus: 1,
      batchGroup: false,
      storefilter: [],
    },
    search: "",
    chipCategoryId: 0,
    sorting: [],
    pagination: {
      pageIndex: 1,
      pageSize: 50,
    },
  },
  adminUsers: {
    search: "",
    pagination: {
      pageIndex: 1,
      pageSize: 50,
    },
    data: {
      storeFilter: [],
      status: "",
    },
  },
  recipeSelectedCategory: { chipCategoryId: 0 },
  devices: {
    data: {
      storefilter: [],
      selectedStatus: "",
    },
    sorting: [],
    pagination: {
      pageIndex: 1,
      pageSize: 50,
    },
    search: "",
  },
  tipsAndTricks: {
    sorting: [],
    pagination: {
      pageIndex: 1,
      pageSize: 50,
    },
    search: "",
  },
  units: {
    sorting: [],
    pagination: {
      pageIndex: 1,
      pageSize: 50,
    },
    search: "",
  },
  rolesAndPermissions: {
    search: "",
  },
  templates: {
    data: {
      selectedStatus: "",
      storefilter: [],
      selectedSchedule: "",
    },
    sorting: [],
    pagination: {
      pageIndex: 1,
      pageSize: 50,
    },
    search: "",
  },
  labelPrinting: {
    data: {
      selectedType: null,
    },
    sorting: [],
    pagination: {
      pageIndex: 1,
      pageSize: 50,
    },
    search: "",
  },
};

export const useAppScope = () => {
  const [appState, setAppState] = useImmer({
    initialLoad: true,
    tableData: initialState,
  });

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("tableParams-recipe"));
    if (data) {
      setAppState((draft) => {
        draft.initialLoad = false;
        draft.tableData = data;
      });
    } else {
      setAppState((draft) => {
        draft.initialLoad = false;
      });
    }
  }, []);

  useEffect(() => {
    if (!appState.initialLoad) {
      sessionStorage.setItem(
        "tableParams-recipe",
        JSON.stringify(appState.tableData)
      );
    }
  }, [appState.tableData]);
  const { data } = useQuery({
    queryKey: bootKeys.lists(),
    queryFn: () => getGlobalData(),
  });

  const setTableState = ({ tableName, key, value }) => {
    setAppState((draft) => {
      draft.tableData[tableName].data[key] = value;
    });
  };
  const setSearchState = ({ tableName, key, value }) => {
    setAppState((draft) => {
      draft.tableData[tableName][key] = value;
    });
  };
  const setSorting = ({ tableName, callback }) => {
    setAppState((draft) => {
      draft.tableData[tableName].sorting = callback(
        draft.tableData[tableName].sorting
      );
    });
  };

  const setPagination = ({ tableName, callback }) => {
    setAppState((draft) => {
      draft.tableData[tableName].pagination = callback(
        draft.tableData[tableName].pagination
      );
    });
  };
  const clearAllFilters = ({ tableName }) => {
    setAppState((draft) => {
      draft.tableData[tableName].data = initialState[tableName].data;
    });
  };

  return {
    globalData: data,
    setTableState,
    tableData: appState.tableData,
    clearAllFilters,
    setSorting,
    setPagination,
    setSearchState,
  };
};
