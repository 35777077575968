import React from "react";
import { BasicMuiTableStyled, InputControl } from "../../../shared";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useCostAndPrice } from "../hooks/useCostAndPrice";
import { formatWeightWithUnits } from "../../../utils";

export const CostAndPricingView = ({
  recipeId,
  ingredientQuery,
  basicDetailsQuery,
}) => {
  const {
    state,
    onChange,
    validator,
    onEditClick,
    onUpdateRetailPriceClick,
    onRetailPriceChange,
    onUpdateOverHead,
    costvalidator,
  } = useCostAndPrice({
    ingredientQuery,
    recipeId,
  });
  const { isEditoverHead, isEditPrice } = state;

  const flattenedIngredients = state.data?.groups
    ? state.data.groups.flatMap((item) => item.ingredients)
    : [];
  const {
    total_cost_ExGst,
    overhead_cost,
    recipe_cost_ExGst,
    products_count,
    retailPriceExcGst,
    retailPriceInGst,
    cost_percentage,
    profit_percentage,
  } = state.data;
  const remainingArray = [
    {
      name: "Total Ex GST:",
      base_unit_qty: "",
      cost: total_cost_ExGst,
      base_unit_symbol: "",
    },
    {
      name: "Overhead Costs",
      base_unit_qty: "",
      cost: overhead_cost,
      base_unit_symbol: "",
    },
    {
      name: "Recipe Cost ex GST:",
      base_unit_qty: "",
      cost: Number(total_cost_ExGst) + Number(overhead_cost),
      products_count: products_count,
      base_unit_symbol: "",
    },
  ];

  const columns = [
    {
      header: "Ingredient Name",
      cell: (row, index) => {
        return (
          <>
            {row.name === "Total Ex GST:" ||
            row.name === "Recipe Cost ex GST:" ? (
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                {row.name}
              </Typography>
            ) : (
              row.name
            )}
          </>
        );
      },
    },
    {
      header: "Quantity",
      cell: (row, index) => {
        return (
          row.base_unit_qty &&
          formatWeightWithUnits(row.base_unit_qty, row.base_unit_symbol, 4)
        );
      },
    },
    {
      header: "Cost (Ex GST)",
      cell: (row, index) => {
        return (
          <>
            {row.name === "Overhead Costs" ? (
              <InputControl
                name="overhead_cost"
                onChange={onChange}
                value={row?.cost}
                size="small"
                disabled={!isEditoverHead}
                InputProps={{
                  startAdornment: <InputAdornment>$</InputAdornment>,
                }}
                error={costvalidator.current.message(
                  "Overhead Costs",
                  row.cost,
                  "required"
                )}
                helperText={costvalidator.current.message(
                  "Overhead Costs",
                  row.cost,
                  "required"
                )}
              />
            ) : row.name === "Recipe Cost ex GST:" ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
                <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                  ${row?.cost?.toFixed(3)}
                </Typography>
                for{" "}
                <InputControl
                  sx={{ width: "80px" }}
                  name="products_count"
                  onChange={onChange}
                  size="small"
                  disabled={!isEditoverHead}
                  value={row.products_count}
                  error={costvalidator.current.message(
                    "Product Count",
                    row.products_count,
                    "required"
                  )}
                  helperText={costvalidator.current.message(
                    "Product Count",
                    row.products_count,
                    "required"
                  )}
                />
                unit(s)
              </Box>
            ) : (
              `$${row.cost}`
            )}
          </>
        );
      },
    },
  ];
  const generateStyle = (item) => {
    let style = {};
    if (item.name === "Total Ex GST:" || item.name === "Recipe Cost ex GST:") {
      style = { ...style, backgroundColor: "#F5F8FC" };
    }
    return style;
  };

  return (
    <>
      <Stack
        padding={"12px 8px"}
        backgroundColor="#fff"
        direction={"row"}
        justifyContent="space-between"
      >
        <Typography variant="h6">Cost Breakdown</Typography>
        <Button
          type="button"
          size="small"
          variant="contained"
          color={isEditoverHead ? "primary" : "inherit"}
          onClick={
            isEditoverHead
              ? onUpdateOverHead
              : () => onEditClick("isEditoverHead")
          }
        >
          {isEditoverHead ? "save" : "Edit"}
        </Button>
      </Stack>
      <Box
        sx={{
          backgroundColor: "#F5F8FC",
          borderTop: "1px solid #DFE8F2",
        }}
      >
        <BasicMuiTableStyled
          data={[...flattenedIngredients, ...remainingArray]}
          columns={columns}
          styleFunc={generateStyle}
        />
      </Box>
      {basicDetailsQuery?.data?.type === "finishedproduct" && (
        <Box
          sx={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Stack
            padding={"12px 24px"}
            direction={"row"}
            justifyContent="space-between"
          >
            <Typography variant="h6">Retail Price</Typography>
            <Button
              type="button"
              size="small"
              variant="contained"
              color={isEditPrice ? "primary" : "inherit"}
              onClick={
                isEditPrice
                  ? onUpdateRetailPriceClick
                  : () => onEditClick("isEditPrice")
              }
            >
              {isEditPrice ? "save" : "Edit"}
            </Button>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            gap={"16px"}
            sx={{
              margin: "10px",
            }}
          >
            <InputControl
              label="Retail Price Ex GST"
              InputProps={{
                startAdornment: <InputAdornment>$</InputAdornment>,
              }}
              value={retailPriceExcGst}
              disabled={!isEditPrice}
              fullWidth
              name="retailPriceExcGst"
              type="text"
              required
              error={validator.current.message(
                "Retail Price Ex GST",
                retailPriceExcGst,
                "required"
              )}
              helperText={validator.current.message(
                "Retail Price Ex GST",
                retailPriceExcGst,
                "required"
              )}
              onChange={onRetailPriceChange}
            />
            <InputControl
              label="Retail Price In GST"
              InputProps={{
                startAdornment: <InputAdornment>$</InputAdornment>,
              }}
              value={retailPriceInGst}
              disabled={!isEditPrice}
              fullWidth
              name="retailPriceInGst"
              type="text"
              required
              error={validator.current.message(
                "Retail Price In GST",
                retailPriceInGst,
                "required"
              )}
              helperText={validator.current.message(
                "Retail Price In GST",
                retailPriceInGst,
                "required"
              )}
              onChange={onRetailPriceChange}
            />
          </Stack>
          <Box
            sx={{
              border: "1px solid #DFE8F2",
              borderRadius: "8px",
              margin: "10px",
            }}
          >
            <Grid
              container
              sx={{ borderBottom: "1px solid #DFE8F2", padding: "10px" }}
            >
              <Grid item xs={6}>
                <Typography fontWeight={500}>Cost percentage</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={500}>{cost_percentage}%</Typography>
              </Grid>
            </Grid>
            <Grid container direction={"row"} sx={{ padding: "10px" }}>
              <Grid item xs={6}>
                <Typography fontWeight={500}>Profit percentage</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={500}>{profit_percentage}%</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};
