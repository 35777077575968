import { Box, Chip, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { CustomDetailsList, CustomTable, Loader } from "../../../shared";
import { createColumnHelper } from "@tanstack/react-table";

export const IngredientsBasicDetails = ({
  state,
  ingredient,
  optionsUnits,
}) => {
  const columnHelper = createColumnHelper();

  const columns = [
    {
      title: "Ingredient Name",
      accessor: (val) => (
        <Typography variant="subtitle2" fontWeight={500}>
          {val.name}
        </Typography>
      ),
    },
    {
      title: "Tax Group",
      accessor: "tax_group_name",
    },
    {
      title: "Type",
      accessor: (val) => (
        <Typography variant="subtitle2" fontWeight={500}>
          {val.type?.charAt(0).toUpperCase() + val.type.slice(1)}
        </Typography>
      ),
    },
    {
      title: "Category tags",
      accessor: (val) => {
        return (
          <Stack direction="row" gap="8px">
            {val?.tags?.map((data) => (
              <Chip size="small" variant="outlined" color="gray" label={data} />
            ))}
          </Stack>
        );
      },
    },
    {
      title: "Buying Unit",
      accessor: (val) => (
        <Typography variant="subtitle2" fontWeight={500}>
          {optionsUnits?.find((c) => c?.id === val?.buying_unit_id)?.name}
        </Typography>
      ),
    },
    {
      title: "Cost (ex GST)",
      accessor: (val) => (
        <Typography variant="subtitle2" fontWeight={500}>
          ${Number(val?.cost_exc_tax)?.toFixed(3)}
        </Typography>
      ),
    },
    {
      title: "Cost (in GST)",
      accessor: (val) => (
        <Typography variant="subtitle2" fontWeight={500}>
          ${Number(val?.cost_inc_tax)?.toFixed(3)}
        </Typography>
      ),
    },
  ];
  const columns2 = [
    columnHelper.accessor("base_unit_qty", {
      header: () => "Base Qty",
    }),
    columnHelper.accessor("base_unit_name", {
      header: () => "Base Unit",
    }),
    columnHelper.accessor("id", {
      header: () => "=",
      cell: (info) => "=",
    }),
    columnHelper.accessor("alt_unit_qty", {
      header: () => "Alternative Unit",
      cell: (row) => {
        return (
          <Stack direction="row" gap={1}>
            <Typography>{row.row.original.alt_unit_qty}</Typography>
            <Typography>{row.row.original.alt_unit_name}</Typography>
          </Stack>
        );
      },
    }),
  ];
  return (
    <>
      {ingredient.isLoading ? (
        <Loader />
      ) : (
        <Stack sx={{ borderRadius: "4px", bgcolor: "#fff", px: "16px" }}>
          <CustomDetailsList
            columns={columns}
            data={ingredient?.data}
            title="Basic Details"
          />
          {ingredient.data.hasUom ? (
            <Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                p="25px 24px 13px 0px"
              >
                <Typography
                  variant="h6"
                  fontWeight={600}
                  lineHeight="19px"
                  minWidth="160px"
                >
                  Alternate Unit Of Measure
                </Typography>
              </Stack>
              <Box
                sx={{
                  p: "0 8px 8px",
                  bgcolor: "#F5F8FC",
                }}
              >
                <CustomTable
                  defaultData={ingredient?.data?.units}
                  columns={columns2}
                  isPagination={false}
                  isTableHeader={false}
                />
              </Box>
            </Stack>
          ) : null}
          <Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              p="25px 24px 13px 0px"
            >
              <Typography
                variant="h6"
                fontWeight={600}
                lineHeight="19px"
                minWidth="160px"
              >
                Spec Sheet
              </Typography>
            </Stack>
            <Box
              sx={{
                p: "0 8px 8px",
                bgcolor: "#F5F8FC",
              }}
            >
              <Box sx={{ width: "100%", padding: "16px" }}>
                <Stack direction="row" spacing={2}>
                  {state.data.pdf.length ? (
                    state.data.pdf.map((file, index) => (
                      <Paper
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "8px",
                          minWidth: "150px",
                        }}
                      >
                        <img src="/icons/ic_file.jpg" />
                        <Box sx={{ marginLeft: "8px", overflow: "hidden" }}>
                          <Typography
                            variant="body2"
                            noWrap
                            component="a"
                            href={file}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                              marginTop: "20px",
                              color: "black",
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                          >
                            {file}
                          </Typography>
                        </Box>
                      </Paper>
                    ))
                  ) : (
                    <Stack
                      width="100%"
                      minHeight="100px"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ textAlign: "center" }}
                    >
                      <Typography
                        fontWeight={500}
                        fontSize="14px"
                        color="#485364"
                      >
                        No spec sheet added
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Stack>
      )}
    </>
  );
};
