import { Box, Tab, Tabs, Link } from "@mui/material";
import { Button, Typography } from "@mui/material";
import { CustomTabPanel, PageListLayout } from "../../../shared";
import { BasicDetails } from "../components/BasicDetails";
import { AddOrEditRecipeIngredient } from "../components/AddOrEditRecipeIngredient";
import { ChartSettings } from "../components/ChartSettings";
import { HowtoPrepare } from "../components/HowtoPrepare";
import { useCreateOrEdit } from "../hooks";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

export const AddOrEditRecipe = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    search,
    basicdetails,
    basicDetailsHandleDropzone,
    validator,
    categoryIds,
    CategoryChangeHandler,
    SubcategoryChangeHandler,
    typeData,
    TypeHandler,
    onHandleChange,
    handleRemoveImage,
    basicDetailsOnChange,
    saveAndSubmit,
    basicDetailsData,
    createImageUrl,
    handleTabCLick,
    ingredients,
    ingredientsList,
    alternativeunitList,
    IngredientsChangehandler,
    unitsChangehandler,
    addIngredientsHandle,
    handleRemoveRows,
    addNewGroup,
    onRadioButtonChange,
    altUnitChangeHandler,
    addUnitsHandle,
    onSwitchChange,
    handleDelete,
    updateTableData,
    onIngredientsOrderChange,

    chartSettings,
    onChange,
    onBreadModularChange,
    onProvingTimeChange,
    onRollMoulderChange,
    onPastrySheeterChange,
    onOvenTimeChange,
    onProductionChange,
    chartRadioButtonChange,
    onButtonChange,
    onBeforeBakingChange,
    onBeforeDecoratingChange,
    handleQuillChange,
    state,
    handleDropzone,
    addStepChangeHandler,
    stepsHandleDelete,
    handleDropzoneVideo,
    handleDeleteVideos,
    createImageVideoUrl,
    handleKeyDown,
    setVideoUrl,
    combinedArray,
    howToPrepareValidator,
    onClearVideoUrl,
    createVideoUrl,
    onOrderChange,
    ingredientsValidator,
    ignoredList,
    removeImage,
    pluOptions,
    optionsTaxGroup,
    sellingUnitChange,
    checkFinishedProduct,
    unitValidator,
    ingredientsDetails,
  } = useCreateOrEdit({
    list: false,
    detailsId: id,
    categorylist: true,
    ingredientslist: true,
    altunitlist: true,
  });

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate("/recipe")}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Recipe
    </Link>,
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate(`/recipe/details/${id}?"basic-details"`)}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      {id ? basicdetails?.data?.name : "Create New Recipe"}
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Edit
    </Typography>,
  ];

  return (
    <PageListLayout
      breadcrumbs={breadcrumbs}
      headerText={id ? basicdetails?.data?.name : "Create New Recipe"}
      pageContent={
        <>
          <Box
            className="sticky-Tab"
            sx={{
              px: "16px",
              bgcolor: "#fff",
              py: "4px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              top: "62px",
            }}
          >
            <Tabs
              className="styled-tabs"
              value={search}
              onChange={handleTabCLick}
            >
              <Tab label="Basic Details" value={null} disabled={!id} />
              <Tab label="Ingredients" value="ingredients" disabled={!id} />
              <Tab label="Chart Settings" value="settings" disabled={!id} />
              <Tab label="How to Prepare" value="prepare" disabled={!id} />
            </Tabs>
            <Box sx={{ textAlign: "end" }}>
              <Button
                onClick={() => {
                  saveAndSubmit(search ?? "basic");
                }}
                sx={{
                  minWidth: "125px",
                  borderColor: "#BCC7DB",
                }}
              >
                {id ? "Save" : "Save & Next"}
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              p: " 8px 16px",
              bgcolor: "#fff",
            }}
            className="tabpanel-class"
          >
            <CustomTabPanel value={search} index={null}>
              <BasicDetails
                createImageUrl={createImageUrl}
                basicdetails={basicdetails}
                basicDetailsHandleDropzone={basicDetailsHandleDropzone}
                validator={validator}
                categoryIds={categoryIds}
                CategoryChangeHandler={CategoryChangeHandler}
                SubcategoryChangeHandler={SubcategoryChangeHandler}
                typeData={typeData}
                TypeHandler={TypeHandler}
                onHandleChange={onHandleChange}
                handleRemoveImage={handleRemoveImage}
                basicDetailsOnChange={basicDetailsOnChange}
                basicDetailsData={basicDetailsData}
                pluOptions={pluOptions}
                optionsTaxGroup={optionsTaxGroup}
              />
            </CustomTabPanel>
            <CustomTabPanel value={search} index={"ingredients"}>
              <AddOrEditRecipeIngredient
                ingredientsValidator={ingredientsValidator}
                unitValidator={unitValidator}
                ingredients={ingredients}
                ingredientsList={ingredientsList}
                alternativeunitList={alternativeunitList}
                IngredientsChangehandler={IngredientsChangehandler}
                unitsChangehandler={unitsChangehandler}
                addIngredientsHandle={addIngredientsHandle}
                handleRemoveRows={handleRemoveRows}
                addNewGroup={addNewGroup}
                onRadioButtonChange={onRadioButtonChange}
                altUnitChangeHandler={altUnitChangeHandler}
                addUnitsHandle={addUnitsHandle}
                onSwitchChange={onSwitchChange}
                handleDelete={handleDelete}
                updateTableData={updateTableData}
                combinedArray={combinedArray}
                onIngredientsOrderChange={onIngredientsOrderChange}
                ignoredList={ignoredList}
                isEditMode={id ? true : false}
                sellingUnitChange={sellingUnitChange}
                checkFinishedProduct={checkFinishedProduct}
                ingredientsDetails={ingredientsDetails}
              />
            </CustomTabPanel>
            <CustomTabPanel value={search} index={"settings"}>
              <ChartSettings
                validator={validator}
                chartSettings={chartSettings}
                onChange={onChange}
                onBreadModularChange={onBreadModularChange}
                onProvingTimeChange={onProvingTimeChange}
                onRollMoulderChange={onRollMoulderChange}
                onPastrySheeterChange={onPastrySheeterChange}
                onOvenTimeChange={onOvenTimeChange}
                onProductionChange={onProductionChange}
                chartRadioButtonChange={chartRadioButtonChange}
                onButtonChange={onButtonChange}
                onBeforeBakingChange={onBeforeBakingChange}
                onBeforeDecoratingChange={onBeforeDecoratingChange}
              />
            </CustomTabPanel>
            <CustomTabPanel value={search} index={"prepare"}>
              <HowtoPrepare
                validator={howToPrepareValidator}
                handleQuillChange={handleQuillChange}
                state={state}
                handleDropzone={handleDropzone}
                addStepChangeHandler={addStepChangeHandler}
                stepsHandleDelete={stepsHandleDelete}
                handleDropzoneVideo={handleDropzoneVideo}
                handleDeleteVideos={handleDeleteVideos}
                createImageVideoUrl={createImageVideoUrl}
                handleKeyDown={handleKeyDown}
                setVideoUrl={setVideoUrl}
                updateTableData={updateTableData}
                onClearVideoUrl={onClearVideoUrl}
                createVideoUrl={createVideoUrl}
                onOrderChange={onOrderChange}
                removeImage={removeImage}
              />
            </CustomTabPanel>
          </Box>
        </>
      }
    />
  );
};
