import { useImmer } from "use-immer";
import { useContext, useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useCreateOrEditQueries } from "./useCreateOrEditQueries";
import { useCategoryQueries } from "../../MasterData/hooks";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import { useRecipeDetails } from "./useRecipeDetails";

export const useCreateOrEdit = ({
  list = true,
  detailsId = null,
  categorylist = true,
  ingredientslist = true,
  altunitlist = true,
  isCreate,
  recipeId,
}) => {
  const { basicDetailsQuery } = useRecipeDetails({ recipeId });
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("tab");
  const typeParams = searchParams.get("type");
  const isIngredientsApiEnabled =
    search === "ingredients" || location.pathname.includes("details");
  const createId = location.state?.createId;
  const { globalData } = useContext(AppContext);
  const [force, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );

  const unitValidator = useRef(
    new SimpleReactValidator({
      validators: {
        customNumeric: {
          message:
            "The value must be a number greater than 0, and cannot be exactly 0.",
          rule: (val) => {
            return parseFloat(val) > 0;
          },
          required: true,
        },
      },
    })
  );
  const howToPrepareValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  );
  const ingredientsValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    })
  ); //////////////////////////////////Ingredients///////////////////////////////////////////////////

  const [ingredients, setIngredients] = useImmer({
    data: {
      recipe_id: "",
      is_alternate_UOM: 0,
      total_weight: "",
      selling_unit_id: "",
      alt_units: [
        {
          base_unit_qty: "",
          base_unit_id: "",
          alt_unit_qty: "",
          alt_unit_id: "",
        },
      ],
      groups: [
        {
          name: "Group 1",
          position: 1,
          total_qty: 0,
          total_qty_unit: "",
          ingredients: [
            {
              rowID: 1,
              groupIndex: 0,
              type: "",
              ingredient_id: "",
              base_unit_id: "",
              base_unit_qty: "",
              is_main_ingredient: 0,
              units: [],
            },
          ],
        },
      ],
    },
    isCreateOpen: false,
  });

  useEffect(() => {
    const kgId = globalData?.defaultUnits.find((i) => i.label === "kg").value;
    if (kgId) {
      setIngredients((draft) => {
        draft.data.alt_units[0].base_unit_id = kgId;
      });
    }
  }, [globalData]);
  const handleSuccessModal = () => {
    navigate(`/recipe/details/${recipeId}/?basic-details`, { replace: true });
  };

  useEffect(() => {
    if (location?.search.includes("status")) {
      setIngredients((draft) => {
        draft.isCreateOpen = true;
      });
    } else {
      setIngredients((draft) => {
        draft.isCreateOpen = false;
      });
    }
  }, [location?.search]);

  useEffect(() => {
    if (basicDetailsQuery?.data?.status === 1) {
      setIngredients((draft) => {
        draft.isCreateOpen = false;
      });
    }
  }, [basicDetailsQuery?.data?.status]);
  const {
    createBasicDetails,
    createIngredients,
    listQuery,
    altunitListQuery,
    createChartSettings,
    createRecipePreapration,
    createImageUrl,
    createImageVideoUrl,
    createVideoUrl,
    updateBasicDetails,
    basicDetailsData,
    updateChartSettings,
    chartSettingsDetails,
    updateIngredients,
    ingredientsDetails,
    updatePreparationSteps,
    preparationStepsDetails,
    pluListQuery,
    taxListQuery,
  } = useCreateOrEditQueries({
    list,
    detailsId,
    ingredientslist,
    altunitlist,
    isIngredientsApiEnabled,
    setIngredients,
  });

  ////////////////////////////////////////////////////Basic Details/////////////////////////////////////////
  const [basicdetails, setBasicDetails] = useImmer({
    data: {
      name: "",
      category_id: "",
      subcategory_id: "",
      type: "",
      is_batch_group: 0,
      bowl_capacity: "",
      image: null,
      status: 0,
      subcategories: [],
      plu: "",
      tax_group_id: "",
    },

    media: "",
    type: "image",
  });

  ///////////////////////////////////Chart Settings//////////////////////////////////////////////////
  const [chartSettings, setChartSettings] = useImmer({
    data: {
      recipe_id: "",
      bread_moulder_enabled: 0,
      front_roller_setting_no: "",
      bread_moulder_setting_chart: "",
      proving_time_enabled: 0,
      proving_time: "",
      oven_time_temp_enabled: 0,
      baking_time: "",
      temperature: "",
      steaming_shots: "",
      cut: "",
      decorate: "",
      oven_temperature_top: "",
      oven_temperature_bottom: "",
      production_wastage_enabled: 0,
      time_period: "",
      primary: 0,
      mandatory: 0,
      roll_moulder_enabled: 0,
      roll_moulder_setting_no: "",
      roll_moulder_rounding_time: "",
      pastry_sheeter_enabled: 0,
      pastry_sheeter_setting_no: "",
      pastry_sheeter_amount_of_turns: "",
      time_through_pastry_sheeter_final_settings: "",
      pastry_sheeter_bottom_weight: "",
      pastry_sheeter_top_weight: "",
      pastry_sheeter_filling_weight: "",
      pastry_sheeter_unbacked_weight: "",
      before_baking: "",
      before_decorating: "",
    },
  });

  ////////////////////////////////////////How to Prepare//////////////////////////////////////////////
  const [state, setState] = useImmer({
    data: {
      steps: [
        // {
        //   step: "",
        //   image_url: "",
        //   imageFile: "",
        //   loading: false,
        // },
      ],
      videos: [],
      method: "",
    },
    type: "",
    media: "",
    videoUrl: "",
  });

  const [value, setValue] = useState(0);

  const handleChange = (value, createId = null) => {
    navigate(value, { replace: true, state: { createId } });
  };

  const handleTabCLick = (event, newValue) => {
    const value = newValue ? `?tab=${newValue}` : "";
    navigate(
      detailsId
        ? `/recipe/edit/${detailsId}${value}`
        : `/recipe/create${value}`,
      { replace: true }
    );
  };

  ///////BasicDetails Query////////

  const { listQuery: categoryListQuery } = useCategoryQueries({
    categorylist,
  });

  const categoryIds = categoryListQuery?.data
    ? categoryListQuery?.data?.map((i) => ({
        label: i?.name,
        value: i?.id,
      }))
    : [];

  const pluOptions =
    pluListQuery?.data?.data &&
    Object.values(pluListQuery?.data?.data).map((item) => ({
      label: item.plu,
      value: item.plu,
      product: item.name,
    }));

  const optionsTaxGroup = taxListQuery?.data?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const typeData = globalData?.Recipe_types;

  const ingredientsList = listQuery?.data?.ingredients
    ? listQuery?.data?.ingredients?.map((i, index) => ({
        label: i?.name,
        value: i?.id,
        type: "ingredient",
        is_batch_group: false,
        units: i?.units.map((item) => ({ ...item, ignoredUnit: false })),
      }))
    : [];

  const recipeList = listQuery?.data?.recipes
    ? listQuery?.data?.recipes?.map((i, index) => ({
        label: i?.name,
        value: i?.id,
        type: "recipe",
        is_batch_group: i?.is_batch_group,
        units: i?.units.map((item) => ({ ...item, ignoredUnit: false })),
      }))
    : [];

  const ignoredList = listQuery?.data?.ignoreUnits
    ? listQuery?.data?.ignoreUnits?.map((i, index) => ({
        alt_unit_symbol: i?.symbol,
        alt_unit_id: i?.id,
        ignoredUnit: true,
      }))
    : [];

  const combinedArray = [...ingredientsList, ...recipeList].map((item) => ({
    ...item,
    units: [...item.units, ...ignoredList],
  }));

  const alternativeunitList = altunitListQuery?.data?.units || [];

  //////////BasicDetails Hooks/////////////////

  const TypeHandler = (event, option, name) => {
    const typeOptions = option ? option.value : "";
    setBasicDetails((draft) => {
      draft.data[name] = typeOptions;
    });
    if (name === "type") {
      if (option?.value != "finishedproduct") {
        setBasicDetails((draft) => {
          draft.data.plu = "";
        });
      }
    }
  };

  const basicDetailsOnChange = (e) => {
    const { name, value } = e.target;
    setBasicDetails((draft) => {
      draft.data[name] = value;
    });
  };

  const onHandleChange = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setBasicDetails((draft) => {
      draft.data.is_batch_group = value;
    });
  };

  const CategoryChangeHandler = (event, option) => {
    const categoryOption = option ? option.value : "";
    const selectedCategory = categoryListQuery?.data?.find(
      (category) => category.id === categoryOption
    );

    const subcategoryForSelectedCategory = selectedCategory
      ? selectedCategory.subData
      : [];

    setBasicDetails((draft) => {
      draft.data.category_id = categoryOption;
      draft.data.subcategories = subcategoryForSelectedCategory;
    });
  };

  const SubcategoryChangeHandler = (event, option) => {
    const subCategoryOption = option ? option.id : "";
    setBasicDetails((draft) => {
      draft.data.subcategory_id = subCategoryOption;
    });
  };

  const basicDetailsHandleDropzone = (e) => {
    const imageFile = e[0];
    setBasicDetails((draft) => {
      draft.media = imageFile;
    });

    const { type } = basicdetails;
    let imagesFormdata = new FormData();
    imagesFormdata.append("type", type);
    imagesFormdata.append("media", imageFile);
    createImageUrl.mutate(imagesFormdata);
  };

  useEffect(() => {
    if (createImageUrl.data) {
      setBasicDetails((draft) => {
        draft.data.image = createImageUrl?.data;
      });
    }
  }, [createImageUrl]);

  const handleRemoveImage = () => {
    setBasicDetails((draft) => {
      draft.media = "";
      draft.data.image = "";
    });
  };

  useEffect(() => {
    if (basicDetailsData.status === "success") {
      setBasicDetails((draft) => {
        draft.data = basicDetailsData.data;
      });
    }
  }, [basicDetailsData.status]);

  const basicDetailsOnSubmit = () => {
    if (validator.current.allValid()) {
      let formData = new FormData();

      const {
        name,
        category_id,
        subcategory_id,
        type,
        is_batch_group,
        bowl_capacity,
        image,
        status,
        plu,
        tax_group_id,
      } = basicdetails.data;

      formData.append("name", name);
      formData.append("category_id", category_id);
      formData.append("subcategory_id", subcategory_id);
      formData.append("type", type);
      formData.append("is_batch_group", is_batch_group);
      formData.append("bowl_capacity", is_batch_group ? bowl_capacity : 0);
      formData.append("status", status);
      formData.append("image", image);
      formData.append("plu", plu);
      formData.append("tax_group_id", tax_group_id);

      detailsId
        ? updateBasicDetails.mutate({ detailsId, formData, handleChange })
        : createBasicDetails.mutate({
            formData,
            handleChange,
          });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  /////ingredients hooks/////////////////

  // add ingredients row
  const addIngredientsHandle = (groupIndex) => {
    const newRows = {
      rowID: ingredients.data.groups[groupIndex].ingredients.length + 1,
      groupIndex: groupIndex,
      ingredient_id: "",
      base_unit_qty: "",
      base_unit_id: "",
      is_main_ingredient: 0,
    };
    setIngredients((draft) => {
      const currentGroup = draft.data.groups[groupIndex];
      currentGroup.ingredients.push(newRows);
    });
  };

  //add altUnits row
  const addUnitsHandle = () => {
    if (unitValidator.current.allValid()) {
      const newAltUnitRows = {
        base_unit_id: "",
        base_unit_qty: "",
        alt_unit_id: "",
        alt_unit_qty: "",
      };

      setIngredients((draft) => {
        const currentRow = draft.data.alt_units;
        currentRow.push(newAltUnitRows);
      });
    } else {
      unitValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const IngredientsChangehandler = (event, option, groupindex, rowindex) => {
    if (option) {
      const selectedIngredientId = option ? option.value : "";
      const unitsForSelectedIngredient = option.units;
      setIngredients((draft) => {
        draft.data.groups[groupindex].ingredients[rowindex].ingredient_id =
          selectedIngredientId;
        draft.data.groups[groupindex].ingredients[rowindex].units =
          unitsForSelectedIngredient;
        draft.data.groups[groupindex].ingredients[rowindex].type = option
          ? option.type
          : "";
      });
    } else {
      setIngredients((draft) => {
        draft.data.groups[groupindex].ingredients[rowindex].ingredient_id = "";
        draft.data.groups[groupindex].ingredients[rowindex].units = [];
        draft.data.groups[groupindex].ingredients[rowindex].type = "";
      });
    }
  };

  const unitsChangehandler = (event, option, index, ingredientIndex) => {
    const unitsOption = option ? option.alt_unit_id : "";
    setIngredients((draft) => {
      draft.data.groups[index].ingredients[ingredientIndex].base_unit_id =
        unitsOption;
    });
  };

  const sellingUnitChange = (e, option) => {
    const unitsOption = option ? option.id : "";
    setIngredients((draft) => {
      draft.data.selling_unit_id = unitsOption;
    });
  };

  const onIngredientsOrderChange = async (data, index) => {
    setIngredients((draft) => {
      draft.data.groups[index].ingredients = data;
    });
  };

  const altUnitChangeHandler = (name, option, index) => {
    const id = option ? option.id : "";
    setIngredients((draft) => {
      draft.data.alt_units[index][name] = id;
    });
  };

  const handleDelete = (index) => {
    setIngredients((draft) => {
      if (index !== 0) {
        draft.data.alt_units = [
          ...draft.data.alt_units.filter((data, i) => i !== index),
        ];
      }
    });
  };

  const handleRemoveRows = (info) => {
    const itemIndex = info.row.index;
    const groupIndex = info?.row?.original?.groupIndex;
    const groupLength = ingredients.data.groups[groupIndex].ingredients.length;
    if (groupLength === 1) {
      const newGroups = ingredients.data.groups.filter((_, index) => {
        return index !== groupIndex;
      });
      const newindices = newGroups.map((grp, grpIndex) => {
        return {
          ...grp,
          ingredients: grp.ingredients.map((item) => ({
            ...item,
            groupIndex: grpIndex,
          })),
        };
      });
      setIngredients((draft) => {
        draft.data.groups = newindices;
      });
    } else {
      setIngredients((draft) => {
        draft.data.groups[groupIndex].ingredients?.splice(itemIndex, 1);
      });
    }
  };

  const addNewGroup = () => {
    setIngredients((draft) => {
      const newPosition = draft.data.groups.length + 1;
      draft.data.groups.push({
        name: `Group ${newPosition}`,
        position: newPosition,
        ingredients: [
          {
            groupIndex: ingredients?.data?.groups?.length,
            ingredient_id: "",
            base_unit_id: "",
            base_unit_qty: "",
            is_main_ingredient: 0,
          },
        ],
      });
    });
  };

  const onRadioButtonChange = (rowId, groupIndex) => {
    const newData = ingredients.data.groups.map((grp, grpIndex) => {
      if (groupIndex === grpIndex) {
        return {
          ...grp,
          ingredients: grp.ingredients?.map((ingredient, index) => ({
            ...ingredient,
            is_main_ingredient:
              index === rowId ? (ingredient.is_main_ingredient ? 0 : 1) : 0,
          })),
        };
      } else {
        return {
          ...grp,
          ingredients: grp.ingredients?.map((ingredient, index) => ({
            ...ingredient,
            is_main_ingredient: 0,
          })),
        };
      }
    });
    setIngredients((draft) => {
      draft.data.groups = newData;
    });
  };
  const onSwitchChange = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setIngredients((draft) => {
      if (draft.data.alt_units.length === 0) {
        draft.data.alt_units = [{ base_unit_id: 0 }];
      }

      draft.data.alt_units[0].base_unit_id = globalData?.defaultUnits.find(
        (i) => i.label === "kg"
      ).value;

      draft.data.alt_units[0].base_unit_qty = ingredients.data.total_weight;
      draft.data.is_alternate_UOM = value;
      if (value === 0) {
        draft.data.selling_unit_id = "";
      }
    });
  };

  /////////////////////////////////////calculation///////////////////////////////////////
  const calculateWeightInGrams = (ingredient, dataArray) => {
    const id = Number(ingredient.base_unit_id);
    const baseQty = Number(ingredient?.base_unit_qty);
    const isIgnoredUnit = ignoredList?.some((item) => item.alt_unit_id === id);

    if (isIgnoredUnit) return { qty: 0 };
    const baseUnitId =
      globalData?.defaultUnits.find((i) => i.label === "gm")?.value || 1;
    const hasBaseUnit = dataArray?.some(
      (item) => item.alt_unit_id === baseUnitId
    );

    if (id && dataArray?.length > 0) {
      let currentBaseUnitId = id;
      let qty = baseQty ? baseQty : 0;

      if (hasBaseUnit) {
        while (baseUnitId !== currentBaseUnitId) {
          const baseUnit = dataArray.find(
            (item) => item.alt_unit_id === currentBaseUnitId
          );
          if (!baseUnit) break;
          currentBaseUnitId = baseUnit?.base_unit_id;
          qty = qty * (baseUnit?.base_unit_qty / baseUnit?.alt_unit_qty);
        }
      } else {
        qty = 0;
      }

      return { qty };
    } else {
      return { qty: 0 };
    }
  };

  const iterateAndCalculateWeight = () => {
    //
    let finalTotal = 0;
    ingredients?.data?.groups.forEach((group) => {
      group?.ingredients?.forEach((ingredient) => {
        const { units } = ingredient;
        const { qty } = calculateWeightInGrams(ingredient, units);
        finalTotal += qty;
      });
    });
    return (finalTotal / 1000).toFixed(4);
  };

  const calculateTotalQtyForGroup = (group) => {
    let totalqty = 0;
    group?.ingredients?.forEach((ingredient) => {
      const { units } = ingredient;
      const { qty } = calculateWeightInGrams(ingredient, units);
      totalqty += qty;
    });
    return (totalqty / 1000).toFixed(4);
  };

  let totalItemWeight = iterateAndCalculateWeight();

  useEffect(() => {
    setIngredients((draft) => {
      draft.data.total_weight = totalItemWeight;
    });

    if (ingredients.data.is_alternate_UOM === 1) {
      setIngredients((draft) => {
        draft.data.alt_units[0].base_unit_qty = totalItemWeight;
      });
    }
  }, [ingredients]);

  useEffect(() => {
    if (
      ingredientsDetails.isSuccess &&
      listQuery.isSuccess &&
      ingredientsDetails?.data?.condition === 1
    ) {
      const groupIds = ingredientsDetails?.data?.groups?.map((group, index) => {
        if (group?.ingredients) {
          return {
            ...group,
            ingredients: group?.ingredients.map((ingredient) => ({
              ...ingredient,
              groupIndex: index,
              units: [...ingredient.units, ...ignoredList],
            })),
          };
        }
        return group;
      });

      const { alternative_uom, groups, total_weight, ...ingredientsData } =
        ingredientsDetails.data;

      let parsedTotalWeight = parseFloat(total_weight);

      setIngredients((draft) => {
        draft.data = {
          ...ingredientsData,
          alt_units: alternative_uom,
          groups: groupIds,
          total_weight: parsedTotalWeight,
        };
      });
    }
  }, [ingredientsDetails.data, listQuery.isSuccess]);

  const checkFinishedProduct = () => {
    let isFinishedProduct = false;
    if (detailsId && ingredients.data.type === "finishedproduct") {
      isFinishedProduct = true;
    } else if (typeParams === "finishedproduct") {
      isFinishedProduct = true;
    } else {
      isFinishedProduct = false;
    }
    return isFinishedProduct;
  };

  const ingredientsOnSubmit = () => {
    if (
      ingredientsValidator.current.allValid() &&
      unitValidator.current.allValid()
    ) {
      const {
        recipe_id,
        alt_units,
        groups,
        is_alternate_UOM,
        total_weight,
        selling_unit_id,
      } = ingredients.data;
      const isFinishedProduct = checkFinishedProduct();
      let formData = new FormData();

      formData.append("recipe_id", createId);
      formData.append("is_alternate_UOM", is_alternate_UOM);
      formData.append("total_weight", total_weight);
      isFinishedProduct && formData.append("selling_unit_id", selling_unit_id);

      // Append alt_units
      if (is_alternate_UOM === 1) {
        alt_units?.map((item, index) => {
          formData.append(
            `alt_units[${index}][base_unit_qty]`,
            item.base_unit_qty
          );

          formData.append(
            `alt_units[${index}][base_unit_id]`,
            item.base_unit_id
          );
          formData.append(
            `alt_units[${index}][alt_unit_qty]`,
            item.alt_unit_qty
          );
          formData.append(`alt_units[${index}][alt_unit_id]`, item.alt_unit_id);
        });
      }

      // Append groups
      groups.map((group, groupIndex) => {
        const totalQtyForGroup = calculateTotalQtyForGroup(group);
        formData.append(`groups[${groupIndex}][total_qty]`, totalQtyForGroup);
        formData.append(`groups[${groupIndex}][total_qty_unit]`, "kg");
        formData.append(`groups[${groupIndex}][name]`, group.name);
        formData.append(`groups[${groupIndex}][position]`, group.position);

        group.ingredients.map((ingredient, ingredientIndex) => {
          formData.append(
            `groups[${groupIndex}][ingredients][${ingredientIndex}][ingredient_id]`,
            ingredient.ingredient_id
          );
          formData.append(
            `groups[${groupIndex}][ingredients][${ingredientIndex}][base_unit_id]`,
            ingredient.base_unit_id
          );
          formData.append(
            `groups[${groupIndex}][ingredients][${ingredientIndex}][base_unit_qty]`,
            ingredient.base_unit_qty
          );
          formData.append(
            `groups[${groupIndex}][ingredients][${ingredientIndex}][is_main_ingredient]`,
            ingredient.is_main_ingredient
          );
          formData.append(
            `groups[${groupIndex}][ingredients][${ingredientIndex}][type]`,
            ingredient.type
          );
        });
      });

      detailsId
        ? updateIngredients.mutate({ detailsId, formData, handleChange })
        : createIngredients.mutate({ formData, handleChange, createId });
    } else {
      ingredientsValidator.current.showMessages();
      unitValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  //////chart settings///////////////

  const onChange = (e) => {
    const { name, value } = e.target;
    setChartSettings((draft) => {
      draft.data[name] = value;
    });
  };

  const onBreadModularChange = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setChartSettings((draft) => {
      draft.data.bread_moulder_enabled = value;
    });
  };

  const onProvingTimeChange = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setChartSettings((draft) => {
      draft.data.proving_time_enabled = value;
    });
  };

  const onOvenTimeChange = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setChartSettings((draft) => {
      draft.data.oven_time_temp_enabled = value;
    });
  };

  const onProductionChange = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setChartSettings((draft) => {
      draft.data.production_wastage_enabled = value;
    });
  };

  const onRollMoulderChange = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setChartSettings((draft) => {
      draft.data.roll_moulder_enabled = value;
    });
  };

  const onPastrySheeterChange = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setChartSettings((draft) => {
      draft.data.pastry_sheeter_enabled = value;
    });
  };

  const chartRadioButtonChange = (value) => {
    setChartSettings((draft) => {
      draft.data.primary = value;
    });
  };

  const onButtonChange = (value) => {
    setChartSettings((draft) => {
      draft.data.mandatory = value;
    });
  };

  const onBeforeBakingChange = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setChartSettings((draft) => {
      draft.data.before_baking = value;
    });
  };

  const onBeforeDecoratingChange = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setChartSettings((draft) => {
      draft.data.before_decorating = value;
    });
  };

  useEffect(() => {
    if (
      chartSettingsDetails?.status === "success" &&
      chartSettingsDetails?.data?.condition === 1
    ) {
      setChartSettings((draft) => {
        draft.data = chartSettingsDetails.data;
      });
    }
  }, [chartSettingsDetails.status]);

  const chartSettingsOnSubmit = () => {
    const payload = {
      ...chartSettings.data,
      recipe_id: createId,
    };
    detailsId
      ? updateChartSettings.mutate({ detailsId, payload, handleChange })
      : createChartSettings.mutate({
          payload,
          handleChange,
          createId,
        });
  };

  ///////////////////how to prepare hooks//////////////////

  const addStepChangeHandler = () => {
    const newRows = {
      step: "",
      image_url: "",
    };
    setState((draft) => {
      const currentRow = draft.data.steps;
      currentRow.push(newRows);
    });
  };

  const onOrderChange = (data) => {
    setState((draft) => {
      draft.data.steps = data;
    });
  };

  const handleQuillChange = (name, value) => {
    setState((draft) => {
      draft.data[name] = value;
    });
  };

  const removeImage = (index) => {
    setState((draft) => {
      draft.data.steps[index].image_url = "";
      draft.data.steps[index].imageFile = "";
    });
  };

  const handleDropzone = (e, index) => {
    const imageFile = e[0];
    setState((draft) => {
      draft.data.steps[index].imageFile = imageFile;
      draft.data.steps[index].loading = true;
    });

    let imageFormdata = new FormData();
    imageFormdata.append("type", "image");
    imageFormdata.append("media", imageFile);
    createImageVideoUrl.mutate(imageFormdata);

    indexRef.current = index;
  };
  const indexRef = useRef();

  useEffect(() => {
    if (createImageVideoUrl.data) {
      const imageIndex = indexRef.current;
      setState((draft) => {
        draft.data.steps[imageIndex].image_url = createImageVideoUrl?.data;
        draft.data.steps[imageIndex].loading = false;
      });
    }
  }, [createImageVideoUrl.data]);

  const handleDropzoneVideo = (event) => {
    const videoFile = event.target.files[0];
    let videoFormdata = new FormData();
    videoFormdata.append("type", "recipe_video");
    videoFormdata.append("media", videoFile);
    createVideoUrl.mutate(videoFormdata);
  };

  useEffect(() => {
    if (createVideoUrl?.data) {
      const videoUrl = createVideoUrl?.data;
      if (videoUrl) {
        if (
          !state.data.videos.some((obj) =>
            Object.values(obj).includes(videoUrl)
          )
        ) {
          setState((draft) => {
            draft.data.videos.push({
              video_url: videoUrl,
            });
          });
        } else {
          console.log(`${videoUrl} does not exist in the array of objects.`);
        }
      }
    }
  }, [createVideoUrl?.data]);

  const stepsHandleDelete = (index) => {
    setState((draft) => {
      draft.data.steps = [...draft.data.steps.filter((data, i) => i !== index)];
    });
  };

  const handleDeleteVideos = (index) => {
    setState((draft) => {
      draft.data.videos = [
        ...draft.data.videos.filter((data, i) => i !== index),
      ];
    });
  };

  useEffect(() => {
    if (
      preparationStepsDetails?.status === "success" &&
      preparationStepsDetails?.data?.condition === 1
    ) {
      setState((draft) => {
        draft.data = preparationStepsDetails.data;
      });
    }
  }, [preparationStepsDetails.status]);

  const setVideoUrl = (e) => {
    setState((draft) => {
      draft.videoUrl = e?.target?.value;
    });
  };

  const onClearVideoUrl = () => {
    setState((draft) => {
      draft.videoUrl = "";
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      videoUrlOnChange();
    }
  };

  const videoUrlOnChange = () => {
    const { videoUrl } = state;
    setState((draft) => {
      draft.data.videos.push({ video_url: videoUrl });
      draft.videoUrl = "";
    });
  };

  const onSubmit = () => {
    if (howToPrepareValidator.current.allValid()) {
      const { steps, videos } = state.data;

      let formData = new FormData();
      formData.append("recipe_id", createId);
      if (detailsId) {
        formData.append("method", state.data.method ?? "");

        videos.map((item, index) => {
          formData.append(`videos[${index}][video_url]`, item.video_url);
        });

        steps.map((img, index) => {
          formData.append(`steps[${index}][image_url]`, img.image_url ?? "");
        });

        steps.map((item, index) => {
          formData.append(`steps[${index}][step]`, item.step);
        });
      } else {
        state.data.method && formData.append("method", state.data.method);

        videos.length &&
          videos.map((item, index) => {
            formData.append(`videos[${index}][video_url]`, item.video_url);
          });
        const hasSteps = steps.some((item) => item.step);
        if (hasSteps) {
          steps.map((img, index) => {
            formData.append(`steps[${index}][image_url]`, img.image_url);
          });

          steps.map((item, index) => {
            formData.append(`steps[${index}][step]`, item.step);
          });
        }
      }

      detailsId
        ? updatePreparationSteps.mutate({ detailsId, formData, handleChange })
        : createRecipePreapration.mutate({
            formData,
            handleChange,
          });
    } else {
      howToPrepareValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const saveAndSubmit = (index) => {
    switch (index) {
      case "basic":
        basicDetailsOnSubmit();
        break;
      case "ingredients":
        ingredientsOnSubmit();
        break;
      case "settings":
        chartSettingsOnSubmit();
        break;
      case "prepare":
        onSubmit();
        break;
      default:
        console.log("no option");
    }
  };

  const updateTableData = (row, columnId, value, type) => {
    const rowIndex = row?.index;
    const groupIndex = row?.original?.groupIndex;
    if (type === "Ingredients") {
      setIngredients((draft) => {
        draft.data.groups[groupIndex].ingredients[rowIndex][columnId] = value;
      });
    } else if (type === "AltUOM") {
      setIngredients((draft) => {
        draft.data.alt_units[rowIndex][columnId] = value;
      });
    } else {
      setState((draft) => {
        if (draft.data.steps[rowIndex]) {
          draft.data.steps[rowIndex][columnId] = value;
        }
      });
    }
  };

  return {
    basicdetails,
    validator,
    categoryIds,
    typeData,
    value,
    basicDetailsHandleDropzone,
    CategoryChangeHandler,
    SubcategoryChangeHandler,
    TypeHandler,
    onHandleChange,
    handleRemoveImage,
    basicDetailsOnChange,
    saveAndSubmit,
    handleChange,
    basicDetailsData,
    createImageUrl,
    handleTabCLick,

    ingredients,
    ingredientsList,
    alternativeunitList,
    updateTableData,
    IngredientsChangehandler,
    unitsChangehandler,
    addIngredientsHandle,
    handleRemoveRows,
    addNewGroup,
    onRadioButtonChange,
    altUnitChangeHandler,
    addUnitsHandle,
    onSwitchChange,
    handleDelete,
    handleSuccessModal,

    chartSettings,
    onChange,
    onBreadModularChange,
    onProvingTimeChange,
    onRollMoulderChange,
    onPastrySheeterChange,
    onOvenTimeChange,
    onProductionChange,
    chartRadioButtonChange,
    onButtonChange,
    onBeforeBakingChange,
    onBeforeDecoratingChange,

    state,
    handleQuillChange,
    handleDropzone,
    addStepChangeHandler,
    stepsHandleDelete,
    handleDropzoneVideo,
    handleDeleteVideos,
    createImageVideoUrl,
    listQuery,
    handleKeyDown,
    setVideoUrl,
    howToPrepareValidator,
    onClearVideoUrl,
    createVideoUrl,
    onIngredientsOrderChange,
    onOrderChange,
    search,
    ingredientsValidator,
    ignoredList,
    combinedArray,
    removeImage,
    pluOptions,
    optionsTaxGroup,
    sellingUnitChange,
    checkFinishedProduct,
    unitValidator,
    ingredientsDetails,
  };
};
