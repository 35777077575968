import {
  Box,
  InputAdornment,
  OutlinedInput,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Loader } from "./Loader";
import emptyIcon from "../../assets/images/Icons/search-table.png";
import CloseIcon from "@mui/icons-material/Close";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { Link } from "react-router-dom";
import { SortableTableHeader } from "./SortableTableHeader";

export const ServerPaginatedtable = (props) => {
  const [headerHover, setHeaderHover] = React.useState(null);
  const {
    defaultData,
    columns,
    headerText,
    rightContent,
    subRow = null,
    customRowStyle = null,
    tableHeaderContent = null,
    isHeaderStyleChange = false,
    isPagination = true,
    isTableHeader = true,
    tableFooter,
    search,
    pagination,
    onSearchChange,
    setPagination,
    paginationProps,
    isLoading = false,
    allRowsExpanded = false,
    manualSortBy = true,
    sorting,
    setSorting,
    updateTableData,
    onRowLink = false,
    onRowAction = false,
    rowClickNewTab = false,
    emptyMessage = "No data found",
    isSearchActive = false,
    isInTab = false,
  } = props;

  const reactTableParams = {
    data: defaultData,
    columns,
    manualPagination: true,
    manualSorting: manualSortBy,
    onGlobalFilterChange: onSearchChange,
    onPaginationChange: setPagination,
    getSubRows: (row) => row[subRow] || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      globalFilter: search,
      pagination,
    },
    meta: {
      updateData: updateTableData,
    },
  };

  /**
   * Sets up sorting parameters for the React table if manual sorting is enabled.
   *
   * @param {boolean} manualSortBy - Indicates if manual sorting is allowed.
   * @param {function} setSorting - Function to update the sorting state.
   * @param {Array} sorting - Current sorting configuration.
   */
  if (manualSortBy && setSorting && sorting) {
    reactTableParams.onSortingChange = setSorting;
    reactTableParams.state.sorting = sorting;
  }

  /**
   * Initializes the React table with the provided parameters.
   *
   * @type {ReturnType<typeof useReactTable>}
   */
  const table = useReactTable(reactTableParams);

  /**
   * Handles mouse hover status over table headers.
   *
   * @param {boolean} status - The hover status to set.
   */
  const handleMouseHover = (status) => setHeaderHover(status);

  React.useEffect(() => {
    if (table && allRowsExpanded) {
      table.toggleAllRowsExpanded(true);
    }
  }, [table, allRowsExpanded]);

  return (
    <>
      <Stack
        sx={{
          borderRadius: "4px",
          bgcolor: "#fff",
          // overflow: "hidden",
          mb: "8px",
        }}
      >
        {isTableHeader && (
          <TableHeader
            setGlobalFilter={onSearchChange}
            headerText={headerText}
            rightContent={rightContent}
            isHeaderStyleChange={isHeaderStyleChange}
            search={search}
          />
        )}
        {tableHeaderContent && (
          <TableHeaderContent tableHeaderContent={tableHeaderContent} />
        )}
        {isLoading ? (
          <Box sx={{ padding: "10px" }}>
            <Loader />
          </Box>
        ) : (
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "0 0 4px 4px  ", backgroundColor: "#F5F8FC" }}
          >
            <Table
              sx={{
                mx: isInTab ? "0" : "8px",
                width: isInTab ? "100%" : "calc(100% - 16px)",
                backgroundColor: "#F5F8FC",
              }}
            >
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => {
                  return (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        if (!header?.column?.columnDef?.hidden) {
                          const canSort = header.column.getCanSort();
                          const headerName = flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          );
                          const sort = header.column.getIsSorted();
                          const isSortActive = sort !== false;

                          return (
                            <TableCell
                              key={header.id}
                              colSpan={header.colSpan}
                              align={"right"}
                              sx={{
                                padding: "10px 8px",
                                backgroundColor: "#F5F8FC",
                              }}
                              onMouseEnter={
                                canSort
                                  ? () => handleMouseHover(header.id)
                                  : undefined
                              }
                              onMouseLeave={
                                canSort
                                  ? () => handleMouseHover(null)
                                  : undefined
                              }
                            >
                              {header.isPlaceholder ? null : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                    justifyContent:
                                      header?.column?.columnDef?.align ===
                                        "right" && "flex-end",
                                    cursor: canSort ? "pointer" : "",
                                  }}
                                  {...{
                                    onClick:
                                      header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {canSort ? (
                                    <SortableTableHeader
                                      headerHover={headerHover}
                                      headerId={header.id}
                                      headerName={headerName}
                                      isSortActive={isSortActive}
                                      sort={sort}
                                    />
                                  ) : (
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                      }}
                                    >
                                      {headerName}
                                    </Typography>
                                  )}
                                  {/*                               
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    lineHeight: "14px",
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </Typography>

                                {{
                                  asc: (
                                    <img
                                      alt="asc"
                                      src={ascendingIcon}
                                      width={14}
                                      height={14}
                                    />
                                  ),
                                  desc: (
                                    <img
                                      alt="desc"
                                      src={descendingIcon}
                                      width={14}
                                      height={14}
                                    />
                                  ),
                                }[header.column.getIsSorted()] ?? null} */}
                                </Box>
                              )}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
              </TableHead>
              <TableBody
                sx={{
                  border: isInTab ? "0" : "1px solid #DFE8F2",
                  bgcolor: "#fff",
                }}
              >
                {table.getFilteredRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => {
                    const style = customRowStyle ? customRowStyle(row) : {};
                    const isCustomRow =
                      row.depth > 0 && row.original.isCustomRow ? true : false;
                    const link =
                      onRowLink && (subRow ? row.depth > 0 : true)
                        ? onRowLink(row.original)
                        : false;
                    return (
                      <TableRow
                        key={row.id}
                        component={link ? Link : null}
                        to={link ? link : undefined}
                        onClick={() => {
                          onRowAction && onRowAction(row.original);
                        }}
                        target={rowClickNewTab ? "_blank	" : ""}
                        rel="noopener noreferrer"
                        sx={{
                          textDecoration: "none",
                          cursor: link || onRowAction ? "pointer" : "default",
                          "&:hover": {
                            backgroundColor:
                              link || onRowAction
                                ? "rgba(55, 65, 81, 0.04)"
                                : false,
                          },
                        }}
                      >
                        {row.getVisibleCells().map((cell) => {
                          if (!cell?.column?.columnDef?.hidden) {
                            return isCustomRow ? (
                              <TableCell
                                key={cell.id}
                                sx={{
                                  padding: "4px",
                                  borderBottom: "1px solid #DFE8F2",
                                  backgroundColor: "#F2F7FC",
                                  ...style,
                                }}
                                align={
                                  cell.column.id === "actions"
                                    ? "right"
                                    : "left"
                                }
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </TableCell>
                            ) : (
                              <TableCell
                                key={cell.id}
                                sx={{
                                  padding: "8px",
                                  borderBottom: "1px solid #DFE8F2",
                                  backgroundColor: false
                                    ? "#D4E4FD"
                                    : "#FFFFFF",
                                  ...style,
                                }}
                                align={
                                  cell.column.id === "actions"
                                    ? "right"
                                    : "left"
                                }
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <EmptyComponent
                    isSearchActive={isSearchActive}
                    emptyMessage={emptyMessage}
                    search={search}
                  />
                )}
              </TableBody>
            </Table>
            {isPagination && (
              <TablePagination
                sx={{
                  bgcolor: "#fff",
                  border: isInTab ? "0" : "1px solid #DFE8F2",
                  borderTop: 0,
                  mx: isInTab ? "0" : "8px",
                }}
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={0}
                count={paginationProps.total}
                rowsPerPage={pagination?.pageSize}
                page={pagination?.pageIndex > 0 ? pagination?.pageIndex - 1 : 0}
                onPageChange={(e) => table.setPageIndex(e)}
                onRowsPerPageChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                  table.setPageIndex(1);
                }}
                ActionsComponent={(subProps) => (
                  <TablePaginationActions
                    {...subProps}
                    lastPage={paginationProps.lastPage}
                  />
                )}
              />
            )}
            {tableFooter && <>{tableFooter}</>}
          </TableContainer>
        )}
      </Stack>
    </>
  );
};

export function TablePaginationActions(props) {
  const { onPageChange, lastPage, page } = props;
  return (
    <Box sx={{ flexShrink: 0, marginLeft: "20px" }}>
      <Pagination
        count={lastPage}
        page={page + 1}
        shape="rounded"
        showFirstButton
        showLastButton
        onChange={(e, page) => onPageChange(page)}
      />
    </Box>
  );
}

const TableHeader = ({
  setGlobalFilter,
  headerText,
  rightContent,
  isHeaderStyleChange,
  search,
}) => {
  return (
    <Stack
      sx={{
        padding: "16px 12px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        bgcolor: isHeaderStyleChange && "#111C2B",
        color: isHeaderStyleChange && "#fff",
        position: "sticky",
        top: "0px",
        background: "#fff",
        zIndex: 99,
        "&::stuck": {
          backgroundColor: "#000",
        },
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "18px",
        }}
      >
        {headerText}
      </Typography>
      {setGlobalFilter && (
        <OutlinedInput
          onChange={(e) => setGlobalFilter(e.target.value)}
          sx={{
            height: "36px",
            borderRadius: "10px",
            width: "200px",
            backgroundColor: isHeaderStyleChange ? "#313C4C" : "#F2F7FC",
            borderColor: isHeaderStyleChange && "#485364",
            "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: isHeaderStyleChange && "#485364",
            },
            "& input::placeholder": {
              color: isHeaderStyleChange && "#ccc",
            },

            "& input": {
              p: " 12.5px 14px 12.5px 4px !important",
            },
            "&:focus-within": {
              width: "400px",
            },
            transition: "width 0.5s",
          }}
          value={search}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon size="small" />
            </InputAdornment>
          }
          endAdornment={
            search && (
              <InputAdornment
                sx={{ cursor: "pointer" }}
                onMouseDown={(e) => {
                  e.preventDefault(); // Prevent default behavior
                  setGlobalFilter("");
                }}
                position="end"
              >
                <CloseIcon size="large" />
              </InputAdornment>
            )
          }
        />
      )}

      {rightContent}
    </Stack>
  );
};

const EmptyComponent = ({ isSearchActive, emptyMessage, search }) => {
  return (
    <TableRow>
      <TableCell
        align="center"
        colSpan={20}
        height={162}
        sx={{ py: 2, padding: "32px 0" }}
      >
        <Stack alignItems="center" gap="8px">
          <img src={emptyIcon} alt="empty" height={56} width={56} />
          <Typography
            sx={{ fontWeight: 400, fontSize: "14px", color: "#485364" }}
          >
            {isSearchActive || search ? "No matches found" : emptyMessage}
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

const TableHeaderContent = ({ tableHeaderContent }) => {
  return (
    <Box
      sx={{
        p: "8px 16px",
        borderBottom: "1px solid #DFE8F2",
      }}
    >
      {tableHeaderContent}
    </Box>
  );
};
